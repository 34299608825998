import { useEffect, useState } from "react";
import { InputField, ReactSelect } from "../../../components/Helpers/FormHelper"
import { fetchData, initialFormState, showAlert, validateForm } from "../../../components/Helpers/Helper"
import { ModalSection } from "../../../components/Helpers/ModalHelper"
import { COMPOSE_MAIL, GET_MAIL_LIST_FOR_IMAP } from "../../../components/Helpers/ApiRoutes";
import { DraftEditor } from "../../../components/Helpers/EditorHelper";
import { useTranslation } from "react-i18next";

const ComposeForm = ({ loadData, reply, setReply }) => {
    const [recipientList, setRecipientList] = useState(null);
    const [message, setMessage] = useState(null);
    const [recipient, setRecipient] = useState(null);
    const [tinyloader, setTinyloader] = useState(false);
    const [fileCount, setFileCount] = useState(0)
    const { t } = useTranslation();

    useEffect(() => {
        fetchData(GET_MAIL_LIST_FOR_IMAP, 'GET', '', true, false, (res) => {
            if (res.status) {
                setRecipientList(res.body);
            }
        })
    }, [])

    const resetForm = () => {
        setRecipient(null);
        setMessage(null);
        setReply(false);
        setFileCount(0);
        initialFormState('compose-form')
    }

    const sendEmail = (e) => {
        if (validateForm(e, "compose-form")) {
            setTinyloader(true)
            let formdata = new FormData(document.getElementById("compose-form"));

            if (reply && reply.reply_id) {
                formdata.append('reply_id', reply.reply_id)
            }

            fetchData(COMPOSE_MAIL, 'POST', formdata, true, true, (res) => {
                setTinyloader(false)
                if (res.status) {
                    loadData()
                    document.getElementById("closeComposeModal").click();
                }
            })
        }
    };

    return (
        <>
            <style jsx="true">{`
        .rdw-embedded-wrapper, .rdw-image-wrapper {
            display: none;
        }
        .rdw-emoji-modal {
            left: -100px !important;
        }
      `}</style>
            <ModalSection modal_id="composeModal" close_btn_id="closeComposeModal" title={t("compose_mail")} size="xl" action={resetForm} footer={<>
                <div className="text-end">
                    <button type="button" className="btn btn-primary" onClick={sendEmail} disabled={tinyloader}>
                        {tinyloader ? <div className="spinner-border spinner-border-sm mx-2" role="status"><span className="visually-hidden">{t("loading")}</span></div> : t("send")}
                    </button>
                    <button type="button" className="btn btn-danger ms-2 rtl_me_2" data-bs-dismiss="modal" onClick={resetForm}>{t("close")}</button>
                </div>
            </>}>
                <form id="compose-form" action="#" method="post" className="row g-3 needs-validation" noValidate>
                    {reply && reply.id ? <>
                        <InputField col={2} name="recipient" label={t("to.subject")} defaultValue={reply.reply_mail} readOnly required />
                        <InputField col={2} name="subject" label={t("form.subject")} defaultValue={reply.subject} readOnly required />
                    </> : <>
                        <ReactSelect size="col-sm-6 mt-2" name="recipient" label={t("form.to")} value={recipient} isClearable required options={recipientList} onChange={(e) => setRecipient((e))} formatGroupLabel={(data) => <div className="bg-primary py-2 text-center text-white m-0 border"> <span>{data.label}</span></div>} />
                        <InputField col={2} name="subject" label={t("form.subject")} defaultValue={""} required />
                    </>}
                    <div>
                        <label className="form-label">{t("form.message")}</label>
                        <div className="col-md-12 border mt-0 mb-2 py-2">
                            <DraftEditor emailChat={true} heigth={200} setEditorError={setMessage} name="message" data={message} toolbarCustomButtons={[<Attachments fileCount={fileCount} setFileCount={setFileCount} />]} />
                        </div>
                    </div>
                </form>
            </ModalSection>
        </>
    )
}

export default ComposeForm

const Attachments = ({ fileCount, setFileCount }) => {

    const removeFiles = () => {
        if (document.getElementById("attachments")) {
            document.getElementById("attachments").value = null
            setFileCount(0)
        }
    }

    const onFileUpload = (e) => {
        let file_count = e.target.files.length
        setFileCount(file_count)
        if (file_count > 5) {
            removeFiles();
            showAlert({ message: "You can upload only 5 attachment at a time.", status: false });
            return false;
        }

        for (var i = 0; i < file_count; i++) {
            var file = e.target.files[i];
            if (file.size > 5 * 1024 * 1024) {
                removeFiles();
                showAlert({ message: "The resume video may not be greater than 5 mb!", status: false });
                return false;
            }
        }
    }

    return (<>
        <div>
            <label className="form-label" role="button">
                <i className={`fa fa-paperclip ${fileCount && 'text-info'}`}></i>
                <input className="d-none" type="file" id="attachments" name="attachments[]" multiple onChange={onFileUpload} />
            </label>
            {parseInt(fileCount) ? <>
                <span className="bg-light py-1 px-2 mx-1 border">
                    {`${fileCount} file selected`} <b role="button" onClick={removeFiles}>&times;</b>
                </span>
            </> : ''}
        </div>
    </>)
}
