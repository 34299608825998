import React, { Suspense, lazy, useEffect, useState } from 'react';
import { Route, Routes } from "react-router-dom";
import Context from './Helpers/Context';
import { fetchData } from './Helpers/Helper';
import { GET_AUTH_USER } from './Helpers/ApiRoutes';
import Layout, { AuthLayout } from './Layouts/Layout';
import { isCandidate, isCompany, setLayoutColor } from './Helpers/RoleHelper';
import { store } from '../redux/store';
import { Provider } from 'react-redux';
import ManagePage from './Layouts/ManagePage';
import PaymentUnauthorized from '../modules/pricing/paymentUnauthorized';
import { RedirectToNextDomain } from './loader';
import $ from 'jquery';
import DyteMeetngs from '../modules/DyteMeeting/DyteMeetings.js';
import CandidateDyteMeeting from '../modules/CandidateMeeting/CandidateDyteMeeting.js';
import CandidateScheduleMeeting from '../modules/CandidateMeeting/CandidateScheduleMeeting.js';
import CandidateHistory from './Partials/CandidateHistory.js';
import MailSystem from '../modules/MailSystem/MailSystem.js';

const Home = lazy(() => import('../modules/Home/Home'));
const Dashboard = lazy(() => import('../modules/Dashboard/Dashboard'));
const About = lazy(() => import('../modules/About/About'));
const Contact = lazy(() => import('../modules/Contact/Contact'));
const Blog = lazy(() => import('../modules/Blog/Blog'));
const Job = lazy(() => import('../modules/Job/job'));
const SignIn = lazy(() => import('../modules/Auth/SignIn'));
const ChangePassword = lazy(() => import('../modules/Auth/ChangePassword'));
const AppliedJob = lazy(() => import('../modules/AppliedJob/AppliedJob'));
const CandidateMeeting = lazy(() => import('../modules/CandidateMeeting/CandidateMeeting.js'));
const MyJobs = lazy(() => import('../modules/MyJobs/MyJobs'));
const CreateJob = lazy(() => import('../modules/CreateJob/CreateJob'));
const JobDetails = lazy(() => import('../modules/Job/JobDetails'));
const CandidateDetails = lazy(() => import('../modules/Candidate/CandidateDetails'));
const CompanyDetails = lazy(() => import('../modules/CompanyDetails/companyDetails'));
const VerifyEmail = lazy(() => import('../modules/Auth/VerifyEmail'));
const Setting = lazy(() => import('../modules/Setting/Setting'));
const FavouriteJob = lazy(() => import('../modules/FavouriteJob/FavouriteJob'));
const ForgotAccount = lazy(() => import('../modules/Auth/ForgotAccount'));
const JobAlert = lazy(() => import('../modules/JobAlert/JobAlert'));
const Notification = lazy(() => import('../modules/Notification/Notification'));
const Pricing = lazy(() => import('../modules/pricing/Pricing'));
const BlogDetails = lazy(() => import('../modules/Blog/BlogDetails'));
const VisitorLog = lazy(() => import('../modules/VisitorLog/VisitorLog'));
const PricingDetails = lazy(() => import('../modules/pricing/PricingDetails'));
const VerifyPayment = lazy(() => import('../modules/pricing/VerifyPayment'));
const PaymentSuccess = lazy(() => import('../modules/pricing/PaymentSuccess'));
const PaymentFailed = lazy(() => import('../modules/pricing/PaymentFailed'));
const Employee = lazy(() => import('../modules/Employee/Employee'));
const AllCategory = lazy(() => import('../modules/Home/Components/AllCategory'));
const Policy = lazy(() => import('../modules/policy/privacyPolicy'));
const Faq = lazy(() => import('../modules/Faq/Faq'));
const BookmarkCandidate = lazy(() => import('../modules/BookmarkCandidate/BookmarkCandidate'));
const Invoice = lazy(() => import('../modules/Invoice/Invoice'));
const Consultant = lazy(() => import('../modules/Consultant/Consultant'));
const ParserLog = lazy(() => import('../modules/ParserLog/ParserLog'));
const PhoneRecording = lazy(() => import('../modules/PhoneRecording/showPhoneRecording'));
const ChatWithJs = lazy(() => import('../modules/ChatWithJs/ChatWithJs'));
const ImapMail = lazy(() => import('../modules/ImapMail/ImapMail'));
const Terms = lazy(() => import('../modules/terms/terms'));
const Staff = lazy(() => import('../modules/staff/staff'));
const ViewApplication = lazy(() => import('../modules/viewApplication'));
const Candidate = lazy(() => import('../modules/Candidate/Candidate'));
const MailChat = lazy(() => import('../modules/mailChat'));
const Meeting = lazy(() => import('../modules/Meeting'));
const JoinCall = lazy(() => import('../modules/VideoCall/VideoCall'));
const Candidates = lazy(() => import('../modules/candidates'));

export default function Routing() {
    const [context, setContext] = useState();
    const [token] = useState(localStorage.getItem('recruitment-token'));

    useEffect(() => {

        let body = document.querySelector('body');
        if(window?.screen?.width && window.screen.width < 1200 && body){
            body.classList.add('layout-fullwidth', 'sidebar_toggle')
        }else{
            body.classList.remove('layout-fullwidth', 'sidebar_toggle')
        } 
    }, [token]);

    useEffect(() => {
        if (token) {
            fetchData(GET_AUTH_USER, 'GET', '', true, false, (res) => {
                if (res.status) {
                    if (res?.body?.company) setLayoutColor(res.body.company.header_color, res.body.company.sidebar_color, res.body.company.text_color)
                    setContext(prevState => ({ ...prevState, auth: res.body }))
                    setTimeout(function() {
                        $('.page-loader-wrapper').fadeOut();
                    }, 100);
                }
            })
        }

    }, [token]);

    return (
        <>
            <Provider store={store}>

                <Context.Provider value={[context, setContext]} >
                    <Routes>
                        {/* Normal Pages routes */}
                        <Route path="/" exact caseSensitive={false} element={<Layout />}>
                            <Route path="/redirect-to/:companyDomainToken/:nextUrl" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><RedirectToNextDomain /></Suspense>} />
                            <Route path="/" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Home /></Suspense>} />
                            <Route path="/home" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Home /></Suspense>} />
                            <Route path="/allcategory" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><AllCategory /></Suspense>} />
                            <Route path="/about-us" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><About /></Suspense>} />
                            <Route path="/contact-us" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Contact /></Suspense>} />
                            <Route path="/privacy-policy" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Policy /></Suspense>} />
                            <Route path="/faq" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Faq /></Suspense>} />
                            <Route path="/blog" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Blog /></Suspense>} />
                            <Route path="/faq" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Faq /></Suspense>} />
                            <Route path="/terms" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Terms /></Suspense>} />

                            <Route path="/blog-details/:slug" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><BlogDetails /></Suspense>} />
                            <Route path="/Job-details/:slug" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><JobDetails /></Suspense>} />
                            <Route path="/candidate-details/:id" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><CandidateDetails /></Suspense>} />
                            <Route path="/jobs" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Job /></Suspense>} />
                            <Route path="/candidate" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Candidate /></Suspense>} />
                            <Route path='/company-details/:id' caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><CompanyDetails /></Suspense>} />
                            <Route path="/pricing" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Pricing /></Suspense>} />
                            <Route path="/pricing-details/:id" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><PricingDetails /></Suspense>} />
                            <Route path="payment-success" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><PaymentSuccess /></Suspense>} />
                            <Route path="/payment-failed" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><PaymentFailed /></Suspense>} />
                            <Route path="/verify-payment" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><VerifyPayment /></Suspense>} />
                            <Route path="/employee" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Employee /></Suspense>} />
                            <Route path="payment-unauthorized" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><PaymentUnauthorized /></Suspense>} />

                        </Route>

                        {/* Unauthorized routes */}
                        <Route path="/" exact caseSensitive={false} element={<AuthLayout />}>
                            <Route path="sign-in" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><SignIn /></Suspense>} />
                            <Route path="sign-up" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><SignIn /></Suspense>} />
                            <Route path="verify-email" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><VerifyEmail /></Suspense>} />
                            <Route path="forgot-password" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><ForgotAccount /></Suspense>} />
                            <Route path="reset-password" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><ForgotAccount /></Suspense>} />
                        </Route>
                        {/* Authorized routes */}
                        <Route path="/" exact caseSensitive={false} element={<AuthLayout authorized />}>
                            <Route path="dashboard" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Dashboard /></Suspense>} />
                            <Route path="change-password" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><ChangePassword /></Suspense>} />
                            <Route path="chat" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><ChatWithJs /></Suspense>} />
                            <Route path="setting" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Setting /></Suspense>} />
                            {/* <Route path="staff" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}>{context?.auth?.role == "company" ? <Staff /> : <ManagePage code={503} />}</Suspense>} /> */}
                            <Route path="notifications" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Notification /></Suspense>} />
                            <Route path="visitor-log" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><VisitorLog /></Suspense>} />
                            <Route path="meeting" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Meeting /></Suspense>} />
                            <Route path="dyte-meeting" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><DyteMeetngs /></Suspense>} />
                            <Route path="join-call" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><JoinCall /></Suspense>} />
                            {isCandidate && <>
                                <Route path="applied-jobs" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><AppliedJob /></Suspense>} />
                                <Route path="favourite-job" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><FavouriteJob /></Suspense>} />
                                <Route path="job-alert" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><JobAlert /></Suspense>} />
                                <Route path="candidate-meeting" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><CandidateMeeting /></Suspense>} />
                                <Route path="candidate-dyte-meeting" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><CandidateDyteMeeting /></Suspense>} />
                                <Route path="candidate-schedule-meeting" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><CandidateScheduleMeeting /></Suspense>} />
                                <Route path="join-call" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><JoinCall /></Suspense>} />
                            </>}

                            {isCompany && <>
                                <Route path="create-jobs" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><CreateJob /></Suspense>} />
                                <Route path="email" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><ImapMail /></Suspense>} />
                                <Route path="mail-system" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><MailSystem /></Suspense>} />
                                <Route path="my-jobs" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><MyJobs /></Suspense>} />
                                <Route path="my-jobs/applications/:job/:group" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><ViewApplication /></Suspense>} />
                                <Route path="bookmark-candidate" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><BookmarkCandidate /></Suspense>} />
                                <Route path="/mail-gun" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><MailChat /></Suspense>} />
                                <Route path="/candidate-history" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><CandidateHistory /></Suspense>} />
                                <Route path="/candidates" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Candidates /></Suspense>} />
                                {/* <Route path="invoice" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}>
                                    {context?.auth?.role == "company" ? <Invoice /> : <ManagePage code={503} />}
                                    </Suspense>} /> */}
                                <Route path="consultant" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Consultant /></Suspense>} />
                                <Route path="parser-log" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><ParserLog /></Suspense>} />
                                 <Route path="phone-call-recording" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><PhoneRecording /></Suspense>} />
                            </>}
                        </Route>
                        {/* Access Denied routes */}
                        <Route path="/" exact caseSensitive={false} element={<AuthLayout authorized company />}>
                            <Route path="staff" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Staff /></Suspense>} />
                            <Route path="invoice" caseSensitive={false} element={<Suspense fallback={<ManagePage skeleton  />}><Invoice /></Suspense>} />
                        </Route>
                        
                        <Route path="/*" caseSensitive={false} element={<ManagePage code={404} />} />
                    </Routes>
                </Context.Provider>
            </Provider>
        </>
    )
}
