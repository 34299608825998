import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import {
  MAIL_CHAT_GET_BY_ID,
  MAIL_CHAT_GET_EMAILS,
  MAIL_CHAT_GET_THREADS,
  MAIL_CHAT_REPLY,
  MAIL_CHAT_SEND,
} from "../../components/Helpers/ApiRoutes";
import headersData from "../headers";

export const mailChatReducers = createApi({
  reducerPath: "mailChatReducers",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_API_URL,
    prepareHeaders: (headers) => headersData(headers),
  }),
  tagTypes: ["MailChat"],
  endpoints: (builder) => ({
    getEmailThreads: builder.query({
      query: (query) => `${MAIL_CHAT_GET_THREADS}?page=${query?.page}`,
      providesTags: ["MailChat"],
    }),

    getEmails: builder.query({
        query: (query) => MAIL_CHAT_GET_EMAILS+`?thread_id=${query?.thread_id}&page=${query?.page}`,
        providesTags: ["MailChat"],
      }),

      getEmailById: builder.query({
        query: (query) => MAIL_CHAT_GET_BY_ID+`/${query?.id}`,
        providesTags: ["MailChat"],
      }),

    createMailChatSend: builder.mutation({
      query: (payload) => ({
        url: MAIL_CHAT_SEND,
        method: "POST",
        body: payload,
      }),
      invalidatesTags: ["MailChat"],
    }),

    createMailChatReply: builder.mutation({
        query: (payload) => ({
          url: MAIL_CHAT_REPLY,
          method: "POST",
          body: payload,
        }),
        invalidatesTags: ["MailChat"],
      }),
  }),
});

export const { 
    useGetEmailThreadsQuery, 
    useGetEmailsQuery,
    useGetEmailByIdQuery,
    useCreateMailChatSendMutation,
    useCreateMailChatReplyMutation
} = mailChatReducers;
