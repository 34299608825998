// import {stringTrim} from "../Helpers/Helper"
const Banner = (props) => {
  return (
    <>
      <style jsx="true">{`
        .banner-section-contact {
          background-image: url(${props.img ??
          "/img/contact-image-banner.jpg"});
          background-position: center center;
          background-size: cover;
          position: relative;
          z-index: 0;
          height: 300px;
        }

        .banner-section-contactnew {
          background-image: url("./img/cb-h5.jpg");
          background-position: center center;
          background-size: cover;
          position: relative;
          z-index: 0;
          height: 300px;
        }

        .banner-section-contact .banner-content h1 {
          font-size: 65px;
          font-weight: 700;
          color: #ffffff;
          max-width: 630px;
          margin: 0 auto 40px;
          text-transform: capitalize;
        }

        .banner-section-contact::before {
          position: absolute;
          content: "";
          width: 100%;
          height: 100%;
          background: rgba(0, 4, 44, 0.6);
          top: 0;
          left: 0;
          z-index: -1;
        }

        .bgWhiteImg {
          position: absolute;
          bottom: -55px;
        }
      `}</style>
      <div className="banner-section-contact" style={{ height: "480px" }}>
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="banner-content text-center">
              <h1 className="break_word" >{props.title} </h1>
                <h6>
                  {props.author || ""}
                  {props.author ? " | " : ""}
                  {props.createdAt || ""}
                </h6>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;
