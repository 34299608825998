import "../job-details.scss";
import { Link, useNavigate } from "react-router-dom";
import {
  fetchData,
  noImageHandle,
  showAlert,
  stringTrim,
} from "../../../components/Helpers/Helper";
import { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Context from "../../../components/Helpers/Context";
import { GET_JOB_DETAILS, VIEW_CANDIDATE_RESUME } from "../../../components/Helpers/ApiRoutes";
import { isCandidate } from "../../../components/Helpers/RoleHelper";
import Banner from "../../../components/Partials/Banner";
import { ModalSection } from "../../../components/Helpers/ModalHelper";
import Skeleton from "react-loading-skeleton";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";
import { Worker } from "@react-pdf-viewer/core";
import { pdfjs } from 'react-pdf';
import DotRoundLoader from "../../../components/loader";

const JobDetail = (props) => {
  const [context] = useContext(Context);
  const { t } = useTranslation();
  const [data, setData] = useState({});
  const [applied, setApplied] = useState(false);
  const [resumes, setResumes] = useState({});
  const [tinyloader, setTinyloader] = useState(false);
  const [applyTinyloader, setApplyTinyloader] = useState(false);
  const navigate = useNavigate();
  const capitalizeFirst = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };
  const [resumeContent, setResumeContent] = useState({content:null,type:null});

  useEffect(() => {
    if (props.job) {
      let user_id = context && context.auth && context.auth.id;
      if (props.setLoading) props.setLoading(true);
      fetchData(
        `${GET_JOB_DETAILS}/${props.job.slug}?user_id=${user_id}`,
        "GET",
        "",
        false,
        true,
        (res) => {
          if (props.setLoading) props.setLoading(false);
          if (res.status) {
            setData(res.body.job);
            setApplied(res.body.job.applied);
            // setResumes(res.body.resumes && res.body.resumes.length > 0 ? res.body.resumes[0] : false)
          } else {
            navigate("/404");
          }
        }
      );
    }
    // eslint-disable-next-line
  }, [props.job, navigate, context]);

  const applyJob = () => {
    setTinyloader(true);
    setApplyTinyloader(true);
    if (context && context.auth) {
      if (isCandidate) {
        if (applied) {
          showAlert({
            message: t("you_are_already_applied_this_job"),
            status: false,
          });
          setApplyTinyloader(false)
        } else {
          if (resumes && resumes.id) {
            fetchData(
              `job/${data.slug}/apply`,
              "POST",
              { resume_id: resumes && resumes.id, cover_letter: "yes" },
              true,
              false,
              (res) => {
                setTinyloader(false);
                setApplyTinyloader(false);
                if (res.status) {
                  setApplied(true);
                  document.querySelector("#closeApplyJob").click();
                }
              }
            );
          } else {
            showAlert({
              message: t("please_complete_your_profile_first"),
              status: false,
            });
            setApplyTinyloader(false)
          }
        }
      } else {
        showAlert({ message: t("please_login_as_a_candidate"), status: false });
        setApplyTinyloader(false)
      }
    } else {
      showAlert({
        message: t("please_login_as_a_candidate_for_apply_a_job"),
        status: false,
      });
      setApplyTinyloader(false)
      navigate("/sign-in");
    }
  };

  const checkSelectedResume = (id) => {
    setApplyTinyloader(true)
    if (!resumes.id) {
      showAlert({
        message: t("please_select_your_resume"),
        status: false,
      });
      setApplyTinyloader(false)
    } else {
      applyJob();
      setApplyTinyloader(true)
    }
  };
  const openPreviousModal = () => {
    document.getElementById("clickById").click();
  };

  const handleViewResume = (resume) => {
    document.querySelector("#closeApplyJob").click();
    const isPDF = resume.file.endsWith('.pdf');
    if (isPDF) {
          console.log('pdf')
          fetchData(`${VIEW_CANDIDATE_RESUME}/${resume?.id}`,"GET","",true,false,
            (file) => {
              const blobFile = new Blob([file], { type: "application/pdf" });
              const fileURL = URL.createObjectURL(blobFile);
              setResumeContent({content:fileURL,type:"pdf"});
            },false,"blob");
      } else {
          console.log('docx')
              setResumeContent({content:`https://view.officeapps.live.com/op/embed.aspx?src=${process.env.REACT_APP_ASSET_URL}${resume.file}`,type:"docx"})
          }
  };
  return (
    <>
      <div className="job-section job-details-section">
        {props.banner ? (
          <Banner title={data && data.title && stringTrim(data.title, 50)} />
        ) : (
          ""
        )}
        {data && data.company ? (
          <>
            <section className={`job-details pb_remove ${props.banner && "ptb-100"}`}>
              <div className="container">
                <div className="row">
                  <div className="col-xl-8">
                    <div className="row">
                      {data && data.company && (
                        <>
                          <div className="col-lg-12">
                            <div className="job-details-text job-details-gap-y">
                              <div className="job-card">
                                <div className="row align-items-center">
                                  <div className="col-lg-2">
                                    <div className="company-logo">
                                      <Link target="_blank"
                                        className="manage_icon"
                                        to={
                                          "/company-details/" + data?.company.id
                                        }
                                        state={{ company: data.company }}
                                      >
                                        <img
                                          src={
                                            data.company &&
                                            data.company.logo_url
                                              ? data.company.logo_url
                                              : ""
                                          }
                                          target="_blank"
                                          alt="logo"
                                          onError={noImageHandle}
                                        />
                                      </Link>
                                    </div>
                                  </div>
                                  <div className="mt-3 col-lg-10 mt-lg-0">
                                    <div className="job-info">
                                      <div className="d-flex justify-content-between">
                                        <h3 className="with_hover_heading">
                                          <div className="withHover">
                                            {data &&
                                              data.title &&
                                              stringTrim(data.title, 30)}
                                          </div>
                                          <div className="withoutHover">
                                            {data.title}
                                          </div>
                                        </h3>
                                        {data.featured === 1 && (
                                          //add badge
                                          <span className="badge bg-success ms-2">
                                            {t("featured")}
                                          </span>
                                        )}
                                        {data.highlight === 1 && (
                                          //add badge
                                          <span className="badge bg-info ms-2">
                                            {t("highlight")}
                                          </span>
                                        )}
                                      </div>
                                      <ul>
                                        <li className="pe-0">
                                          <i className="fa fa-location pe-0"></i>{" "}
                                          {data.address
                                            ? `${data.address}, `
                                            : ""}
                                          {data.country}
                                        </li>
                                        <li>
                                          <i className="fa fa-user ms-2"></i>
                                          {data.role && data.role.name}
                                        </li>
                                        <li>
                                          <i className="bx bx-briefcase"></i>
                                          {data.job_type && data.job_type.name}
                                        </li>
                                      </ul>
                                      <span>
                                        <i className="bx bx-paper-plane"></i>
                                        {t("apply_before")}: {data.deadline}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="details-text mt-4">
                                <h3>{t("description")}</h3>
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: data.description,
                                  }}
                                ></div>
                              </div>
                              {/* <div className="details-text">
                            <h3>{t("vision")}</h3>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: data?.company?.vision,
                              }}
                            ></div>
                          </div> */}
                              <div className="details-text mt-4">
                                <h3>{t("job_details")}</h3>
                                <div className="row">
                                  
                                  <div className="col-lg-6">
                                  <div className="table-responsive ">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <th>
                                            <span>{t("job_role")}</span>
                                          </th>
                                          <td>{data.role && data.role.name}</td>
                                        </tr>
                                        <tr>
                                          <th>
                                            <span>{t("salery_type")}</span>
                                          </th>
                                          <td>{data.salary_type && data.salary_type.name}</td>
                                        </tr>
                                        <tr>
                                          <th>
                                            <span>{t("job_type")}</span>
                                          </th>
                                          <td>
                                            {data.job_type &&
                                              data.job_type.name}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>
                                            <span>{t("location")}</span>
                                          </th>
                                          <td>{data.full_address}</td>
                                        </tr>
                                        <tr>
                                          <th>
                                            <span>{t("form.email")}</span>
                                          </th>
                                          <td>
                                            <a
                                              href={`mailto:${
                                                data.company &&
                                                data.company.user &&
                                                data.company.user.email
                                              }`}
                                            >
                                              {data.company &&
                                                data.company.user &&
                                                data.company.user.email}
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                  </div>
                                  <div className="col-lg-6">
                                    <div className="table-responsive table_manage_border">
                                    <table className="table">
                                      <tbody>
                                        <tr>
                                          <th>
                                            <span>{t("experince")}</span>
                                          </th>
                                          <td>
                                            {data.experience &&
                                              data.experience.name}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>
                                            <span>{t("salary")}</span>
                                          </th>
                                          <td>
                                            ${data.min_salary}-$
                                            {data.max_salary}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>
                                            <span>{t("remote")}</span>
                                          </th>
                                          <td>
                                            {data.is_remote
                                              ? t("yes")
                                              : t("no")}
                                          </td>
                                        </tr>
                                        <tr>
                                          <th>
                                            <span>{t("website")}</span>
                                          </th>
                                          <td>
                                            <a
                                              href={
                                                data.company &&
                                                data.company.website
                                              }
                                            >
                                              {data.company &&
                                                data.company.website}
                                            </a>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {isCandidate && props.banner && (
                                <>
                                  <div className="theme-btn">
                                    {data?.status === "active" ? (
                                      context?.auth?.resume_files?.length ? (
                                        <button
                                          className="default-btn"
                                          data-bs-toggle="modal"
                                          data-bs-target={"#applyJob"}
                                          id="clickById"
                                          disabled={applied ? true : false}
                                        >
                                          {applied
                                            ? t("applied")
                                            : t("apply_now")}
                                        </button>
                                      ) : (
                                        <button
                                          className="default-btn"
                                          onClick={() => applyJob()}
                                          disabled={applied ? true : false}
                                        >
                                          {applied
                                            ? t("applied")
                                            : t("apply_now")}
                                        </button>
                                      )
                                    ) : (
                                      <button
                                        className="default-btn"
                                        disabled={true}
                                      >
                                        {data?.status === "pending"
                                          ? t("comming_soon")
                                          : t(data.status)}
                                      </button>
                                    )}
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mt-3 mt-xl-0 col-xl-4">
                    <div className="job-sidebar">
                      <h3>{t("posted_by")}</h3>
                      <div className="posted-by">
                        {data.company && data.company.user && (
                          <>
                            <img
                              src={
                                data?.creator?.staff?.photo ||
                                "/images/no-image.png"
                              }
                              className="rounded-circle"
                              alt="client"
                              onError={noImageHandle}
                            />
                            <h4>{data.creator.name}</h4>
                            <span>
                              {capitalizeFirst(data?.creator?.staff?.role)}{" "}
                              {data?.creator?.staff?.role ? " of " : ""}
                              {data.company.company_name}
                            </span>
                          </>
                        )}
                      </div>
                    </div>

                    <div className="job-sidebar">
                      {data?.skill_ids && data?.skill_ids.length > 1 ? (
                        <div>
                          <h3>{t("form.skill")}</h3>
                        </div>
                      ) : (
                        <h3>{t("skill")}</h3>
                      )}

                      <ul>
                        {data?.skill_ids &&
                          data?.skill_ids.map((skill, key) => (
                            <li key={key}>
                              <a className="pe-none" href="/">
                                {skill.name}
                              </a>
                            </li>
                          ))}
                        {!data?.skill_ids && !data?.skill_ids ? (
                          <li>
                            <p className="pe-none">{t("update your skills")}</p>
                          </li>
                        ) : null}
                      </ul>
                    </div>

                    <div className="job-sidebar">
                      <h3>{t("location")}</h3>
                      <iframe
                        className="w-100"
                        title="map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d387190.27991517034!2d-74.25987556253516!3d40.697670063539654!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2sbd!4v1588772651198!5m2!1sen!2sbd"
                      ></iframe>
                    </div>
                    {data.company &&
                      data.company.user &&
                      data.company.user.social_info &&
                      data.company.user.social_info.length > 0 && (
                        <>
                          <div className="job-sidebar social-share">
                            <h3>
                              {t("share_in")} {data.company.company_name}{" "}
                            </h3>
                            <ul>
                              {data.company.user.social_info.map((si, key) => {
                                return (
                                  <li key={key}>
                                    {si.social_media === "other" ? (
                                      <Link
                                        to={si.url}
                                        target="_blank"
                                        title={si.social_media}
                                      >
                                        <i
                                          className={`fa fa-chevron-circle-right`}
                                        ></i>
                                      </Link>
                                    ) : (
                                      <Link
                                        to={si.url}
                                        target="_blank"
                                        title={si.social_media}
                                      >
                                        <i
                                          className={`bx bxl-${si.social_media}`}
                                        ></i>
                                      </Link>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </section>
          </>
        ) : (
          <Skeleton height={500} width={"100%"} count={1} />
        )}
      </div>
      <ModalSection
        title={t("apply_job")}
        modal_id="applyJob"
        size="xl"
        close_btn_id="closeApplyJob"
        footer={
          <div className="row">
            <div className="theme-btn">
              <button
                className="default-btn"
                onClick={() => checkSelectedResume()}
                disabled={tinyloader}
              >
                {!applyTinyloader ? (
                  applied ? (
                    t("applied")
                  ) : (
                    t("apply_job")
                  )
                ) : (
                  <div
                    className="spinner-border spinner-border-sm mx-3"
                    role="status"
                  >
                    <span className="visually-hidden">
                      {t("loading")}
                    </span>
                  </div>
                )}
              </button>
            </div>
          </div>
        }
      >
        <div className="row">
          <div className="main-pdf-box1">
            {context?.auth?.resume_files?.map((resume, index) => {
              return (
                <div
                  className={`mb-3 pdf-box ${
                    resumes.id === resume.id ? "resume_selected" : ""
                  }`}
                  key={index}
                  onClick={() => {
                    setResumes(resume);
                  }}
                >
                  <div className="pdf-icon position-relative">
                    <img src="/images/pdf.png" alt="" />
                    <div
                      className="show-button showBtnNew"
                      data-bs-toggle="modal"
                      onClick={() => handleViewResume(resume)}
                      data-bs-target={"#viewResume_"}
                    >
                      <div className="preview-btn">
                        <i className="fa-solid fa-eye"></i>
                      </div>
                    </div>
                  </div>
                  <p className="text-center mt-1 p-0 m-0">
                    {resume.name.substring(0, 10)}
                    {resume.name.length > 10 && "..."}
                  </p>

                  {/* <ViewButtonModal  className={"eye_btn"} /> */}
                </div>
              );
            })}
          </div>
        </div>
      </ModalSection>
      <div className="mt-5">
            <ModalSection
              modal_id={"viewResume_"}
              title={t("nav.resume")}
              size="xl"
              action={() => openPreviousModal()}
            >
              <div>
            {resumeContent.content === null ?<DotRoundLoader />:
                resumeContent.content && resumeContent.type === "pdf" ? (
                    <Worker
                      workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`}
                    >
                      <Viewer fileUrl={resumeContent?.content} />
                    </Worker>
                  ) : (
                    resumeContent.content && resumeContent.type === "docx" ? <iframe title="Doc" src={resumeContent?.content} height={800} width={"100%"}></iframe>:null
                  )}
          </div>
               </ModalSection>     
      </div>
    </>
  );
};

export default JobDetail;
