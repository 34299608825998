import { AuthSection } from "../../components/Layouts/Layout";
import "./email.scss"
import { useTranslation } from "react-i18next";
import MailFilter from "./Components/MailFilter";
import { useEffect, useState } from "react";
import { fetchData } from "../../components/Helpers/Helper";
import { GET_MAIL, SEEN_MAIL } from "../../components/Helpers/ApiRoutes";
import { now } from "lodash";
import SideMenu from "./Components/SideMenu";
import MailList from "./Components/MailList";
import MailDetails from "./Components/MailDetails";
import ComposeForm from "./Components/ComposeForm";
import DotRoundLoader from "../../components/loader";
import MailSetting from "./Components/MailSetting";

const MailSystem = () => {
    const { t } = useTranslation();
    const [mailCount, setMailCount] = useState(null);
    const [mailData, setMailData] = useState(null);
    const [page, setPage] = useState(1);
    const [refresh, setRefresh] = useState(null);
    const [search, setSearch] = useState("");
    const [mailBox, setmailBox] = useState('inbox');
    const [details, setDetails] = useState(false);
    const [reply, setReply] = useState(false);
    const [loader, setLoader] = useState(true);
    const [check, setCheck] = useState("manage_email_toggle");
    const [completeEmailSetting, setCompleteEmailSetting] = useState(false);

    useEffect(() => {
        setLoader(true)
        fetchData(`${GET_MAIL}?type=${mailBox}&page=${page}&search=${search}`, 'GET', '', true, false, (res) => {
            if (res.status) {
                setCompleteEmailSetting(true);
                setMailData(res.body.mail)
                setMailCount(res.body.count)
                setLoader(false)
            }else{
                setLoader(false)
                if(document.getElementById("mail-setting-btn")){
                    document.getElementById("mail-setting-btn").click();
                }
            }
        })
    }, [mailBox, page, refresh, search])

    const onMenuChange = (menu) => {
        if (mailBox !== menu) {
            setMailData(null)
            setCheck("manage_email_toggle")
        }
        setPage(1)
        setDetails(false)
        setmailBox(menu)
    }

    const viewDetails = (item) => {
        setDetails(item);
        fetchData(`${SEEN_MAIL}/${item.id}`, 'GET', '', true, false, (res) => {
            if (res.status) {
                setRefresh(now);
            }
        })
    }

    const loadData = () => {
        setDetails(false)
        setRefresh(now());
    }

    return (
        <div className="mail_wrapper position-relative">
            <AuthSection icon={"fa fa-envelope"} breadCrumbs={<span className="breadcrumb-item active">{t("imap_mail")}</span>}>
                <div className="mail-inbox">
                    <button type="button" className="btn btn-dark email_toggle_btn" onClick={() => (check === "manage_email_toggle") ? setCheck("email_toggle_left_show") : setCheck("manage_email_toggle")} ><i className="fa fa-bars"></i></button>
                    <div className={`${check}`}>
                        <SideMenu mailBox={mailBox} count={mailCount} onMenuChange={onMenuChange} completeEmailSetting={completeEmailSetting} />
                    </div>
                    <ComposeForm loadData={loadData} reply={reply} setReply={setReply} />
                    <MailSetting completeEmailSetting={completeEmailSetting} setCompleteEmailSetting={setCompleteEmailSetting} />
                    {details ? <MailDetails details={details} setDetails={setDetails} setReply={setReply} /> : <>
                        <div className="mail-right">
                            <MailFilter completeEmailSetting={completeEmailSetting} mailBox={mailBox} setPage={setPage} setRefresh={setRefresh} setSearch={setSearch} mailData={mailData} loadData={loadData} />
                            {loader ? <div className="w-100"><DotRoundLoader /></div> : <MailList mailBox={mailBox} mailData={mailData} viewDetails={viewDetails} />}
                        </div>
                    </>}
                </div>
            </AuthSection>
        </div>
    )
}

export default MailSystem