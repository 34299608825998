import { useEffect, useState } from "react";
import DataTables, { reloadDataTable } from "../../components/Helpers/Datatables";
import { CANDIDATE_DYTE_MEETING } from "../../components/Helpers/ApiRoutes";
import { createRoot } from "react-dom/client";
import { AuthSection } from "../../components/Layouts/Layout";
import { useTranslation } from "react-i18next";

const CandidateDyteMeeting = () => {
    const { t } = useTranslation();
    const [reload] = useState()
    const [dt] = useState({
        dt_url: CANDIDATE_DYTE_MEETING,
        dt_name: "candidate-dyte-meeting",
        dt_column: [
            { data: "DT_RowIndex", name: "id", title: "*", sortable: false, searchable: true, className: "noExport" },
            { data: "meeting_title", name: "meeting_title", title: "Meeting Title" },
            { data: "get_meetings.meeting_status", name: "get_meetings.meeting_status", title: "Meeting Status" },
            { data: "meeting_date", name: "meeting_date", title: "Date/Time" },
            { data: "participant_name", name: "participant_name", title: "Host" },
            { data: "job_info.title", name: "job_info.title", title: "Job" },
            { data: "id", name: "id", title: "Action", sortable: false, searchable: true, className: "noExport" },
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                         {records && records.get_meetings && records.get_meetings.map((val,index) =>{
                            return(
                                <div className="d-flex text-nowrap" key={index}>
                                {val.meeting_title}
                            </div>
                            ) 
                         })} 
                    </>)
                }
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                         {records && records.get_meetings && records.get_meetings.map((val,index) =>{
                            return(
                                <div className="d-flex text-nowrap" key={index}>
                                {val.meeting_status}
                            </div>
                            ) 
                         })} 
                    </>)
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        {records && records.get_meetings && records.get_meetings.map((val,index) =>{
                           return(
                               <div className="d-flex text-nowrap" key={index}>
                               {val.meeting_date}   {val.meeting_time}
                           </div>
                           ) 
                        })} 
                   </>)
                },
            },
            {
                targets: 5,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                         {records && records.job_info && records.job_info.map((val,index) =>{
                            return(
                                <div className="d-flex text-nowrap" key={index}>
                                {val.title}
                            </div>
                            ) 
                         })} 
                    </>)
                }
            },
            {
                targets: 6,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                           <button><i className="fa fa-link"></i></button>
                        </div>
                    </>)
                }
            },
        ],


    });

    useEffect(() => {
        reloadDataTable(dt, CANDIDATE_DYTE_MEETING);
    }, [dt, reload]);


    return (
        <AuthSection icon={"fa fa-bullhorn"} breadCrumbs={<span className="breadcrumb-item active">{t("nav.candidate_dyte_meeting")}</span>}>
        <DataTables dt={dt} dt_name="candidate-dyte-meeting" />
        </AuthSection>
    )
}

export default CandidateDyteMeeting