import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Aside from "./Aside";
import { useEffect, useMemo, useState } from "react";
import { scrollbarSetToTop } from "../Helpers/Helper";
import FrontendHeader from "./FrontendHeader";
import FrontendFooter from "./FrontendFooter";
import Header from "./Header";
import { useTranslation } from "react-i18next"
import BreadCrumbs from "./BreadCrumbs";
import ManagePage from "./ManagePage";
import { useContext } from "react";
import Context from "../Helpers/Context";
import DotRoundLoader from "../loader";
import { SetLayoutColor } from "../Helpers/RoleHelper";
import JoinDyteMeeting from "../Dyte/JoinDyteMeeting";

export const Layout = (props) => {
    const locatoin = useLocation()
    const [load, setLoad] = useState(false);

    useEffect(() => {
        scrollbarSetToTop();
        setLoad(true)
    }, [locatoin.pathname])

    return (
        <>
            {load ? <>
                <FrontendHeader />
                <Outlet />
                <FrontendFooter />
            </> : <ManagePage />}
        </>
    )
}


export const AuthLayout = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const [context, setContext] = useContext(Context);
    const [load, setLoad] = useState(false);

    useEffect(() => {
        scrollbarSetToTop()
        let token = localStorage.getItem('recruitment-token')
        if (token && !props.authorized) {
            navigate('/dashboard')
        } else if (!token && props.authorized) {
            navigate('/sign-in')
        }
        setLoad(true)
    }, [navigate, props]);

    useEffect(() => {
        if (context && context.auth) {
            if (context.auth.user_details && context.auth.user_details.profile_complete !== 0 && window.location.pathname !== '/setting') navigate('/setting');
        }
    }, [context, navigate])

    return (
        <>
            {load ? <>
                {props.authorized ? <>
                    <div id="wrapper" className="theme-cyan">
                        <div className="page-loader-wrapper d-none1">
                            <div className="loader">
                                <div className="m-t-30">
                                    <div className="spinner-border spinner-border-lg mx-3 text-white" role="status"><span className="visually-hidden"></span></div>
                                </div>
                                <p>{t("please_wait")}</p>
                            </div>
                        </div>
                        <Header />
                        <Aside />
                        <div id="main-content">
                            {props.company ? <>
                                {context && context.auth && <>
                                    {context.auth.role === "company" ? <Outlet /> : <ManagePage code={503} />}
                                </>}
                            </> : <Outlet />}
                        </div>
                    </div>
                    <SetLayoutColor primary={context?.auth?.company?.header_color ?? "#061322"} secondary={context?.auth?.company?.sidebar_color ?? "#061322"} text={context?.auth?.company?.text_color ?? "#ffffff"} />
                    <JoinDyteMeeting data={context?.dytemeeting} setContext={setContext} />
                </> : <Outlet />}
            </> : <ManagePage />}
        </>
    )
}

export const AuthSection = (props) => {
    const loading = useMemo(() => {
        return props.loading === false ? false : true
    }, [props.loading]);

    const [load, setLoad] = useState(false)

    useEffect(() => {
        setTimeout(() => setLoad(true), 1000);
        document.querySelectorAll('div[role="tooltip"]').forEach((ele) => ele.remove())
    }, [loading])

    return (
        <div className="container-fluid">
            <BreadCrumbs btn={props.btn} icon={props?.icon}>
                {props.breadCrumbs}
            </BreadCrumbs>
            <div className="row clearfix mobile_card">
                <div className="col-lg-12 mobile_card_padding">
                    <div className="card custom_card cardNew box-shadow">
                        {!load && <DotRoundLoader />}
                        <div className={`body ${load ? "" : "opacity-0"}`}>
                            {props.children}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Layout;