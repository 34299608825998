import _ from "lodash";
import 'intl-tel-input/build/css/intlTelInput.css';
import intlTelInput from 'intl-tel-input';
import bootstrap from 'bootstrap/dist/js/bootstrap';
import $ from 'jquery';
import { useTranslation } from "react-i18next"
import CryptoJS from 'crypto-js';
import { currentLangMode } from "../Lang/ChangeLanguage";
import moment from "moment";

const API_URL = process.env.REACT_APP_API_URL
const CHATIFY_API_URL = process.env.REACT_APP_CHATFY_URL

export const showAlert = (data, form = '', show_toast = true) => {
    if (data.message && show_toast) {
        let idGenerate = _.floor((_.random(0, 9) * 1000000) + 1)
        let alertType = (data.status === true) ? 'success' : 'danger'
        let lang = currentLangMode()

        const htmlToast = `<div id="${idGenerate}" class="toast fade bg-white mb-2 border-0" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="4000"><div class="d-flex bg-${alertType}">
                <div class="toast-body fw-medium text-white">
                    ${data.message}
                </div>
                <button type="button" class=" btn-close ${lang.dir === 'rtl' ? 'me-auto' : 'ms-auto'} ms-2 me-2 mt-2 min-wh-20px" data-bs-dismiss="toast" aria-label="Close"></button></div>
            </div>`;

        $('#toastContainer').html(htmlToast)

        let getIdToast = document.getElementById(idGenerate);
        let toast = new bootstrap.Toast(getIdToast);
        toast.show();
        getIdToast.addEventListener('hidden.bs.toast', function () {
            setTimeout(() => {
                this.remove();
            }, 700000);
        });

    }
    else {
        if(data.errors){
            let i = 1;
            for (let key in data.errors) {
                if (i === 1) {
                    $(`input[name="${key}"]`).closest('form').find('.invalid-custom-feedback').remove();
                    $(`input[name="${key}"]`).closest('form').removeClass('was-validated');
                }
    
                $(`${form} input[name="${key}"]`).addClass('is-invalid');
                $(`${form} input[name="${key}"]`).after(`<div class="invalid-feedback invalid-custom-feedback d-block manage_avtart">${data.errors[key][0]}</div>`)
                i++;
            }
        }
    }
}

export const validateForm = (e, formId = '', exclude = []) => {
    e.preventDefault()
    let selector = formId === '' ? '.needs-validation' : `#${formId}.needs-validation`;
    let error = 0;

    Array.prototype.slice.call(document.querySelectorAll(selector))
        .forEach(function (form) {
            if (!form.checkValidity()) {
                error++;
                e.preventDefault();
                e.stopPropagation();
            }
            form.classList.add('was-validated')
        })

    let react_select_error = 0;
    document.querySelectorAll(`.react-select`).forEach(ele => {
        ele.classList.remove('is-invalid')
        ele.classList.add('is-valid')
    })

    // validate empty space in input 
    document.querySelectorAll(`${selector} input[required]`).forEach(ele => {
        //check exclude field and skip validation
        if (exclude.includes(ele.name)) return true;
        if (ele.value.trim() === '') {
            ele.classList.remove('is-valid')
            ele.classList.add('is-invalid')
            if (ele.nextElementSibling && ele.nextElementSibling.classList.contains('invalid-feedback')) {
                ele.nextElementSibling.remove()
            }
            // remove old error message
            // $(`input[name="${ele.name}"]`).closest('form').find('.invalid-custom-feedback').remove();
            $(`${selector} input[name="${ele.name}"]`).after(`<div class="invalid-feedback invalid-custom-feedback d-block">This Field is required.</div>`)
            error++;
        }
    })


    let react_select_selector = formId === '' ? `.react-select input[required]` : `#${formId} .react-select input[required]`;
    document.querySelectorAll(react_select_selector).forEach(ele => {
        // if (react_select_error === 0) {
        //     $(`input[name="${ele.name}"]`).closest('form').find('.invalid-custom-feedback').remove();
        // }
        if (ele.nextElementSibling && ele.nextElementSibling.classList.contains('invalid-feedback')) {
            ele.nextElementSibling.remove()
        }
        ele.parentElement.classList.remove('is-valid')
        ele.parentElement.classList.add('is-invalid')
        $(`${selector} input[name="${ele.name}"]`).after(`<div class="invalid-feedback invalid-custom-feedback d-block">This Field is required.</div>`)
        react_select_error++;
    })

    if (error || react_select_error) {
        return false
    } else {
        return true
    }
}

export const fetchData = async (url, method, data, token, process, res, abort_signal = false, process_type = false, loading = true, form_id = '', alert = true, show_toast = true) => {
    if (loading) loadingData(true)
    let headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'localization': localStorage.getItem('localization') ?? 'en'
    }

    if (process) {
        headers = { ...headers, 'contentType': false, 'processData': false }
    } else {
        headers = { ...headers, 'Content-Type': 'application/json' }
    }

    if (token) {
        let TOKEN = localStorage.getItem('recruitment-token')
        headers = { ...headers, 'Authorization': 'Bearer ' + TOKEN }
    }

    let request = {
        'method': method,
        'headers': headers,
    }

    if (abort_signal) {
        request = { ...request, 'signal': abort_signal }
    }

    if (data) {
        request = { ...request, 'body': process ? data : JSON.stringify(data) }
    }
    await fetch(`${API_URL}${url}`, request).then((response) => {
        if (response.status === 404) {
            window.location.href = '/404'
        } else {
            return process_type === "text" ? response.text() : (process_type === "blob" ? response.blob() : response.json())
        }
    }).then((json) => {
        if (loading) loadingData()
        if (json.message === "Unauthenticated." || json.type === "block_user") {
            if (json.type === "block_user") localStorage.setItem("block_user", JSON.stringify(json))
            localStorage.removeItem("recruitment-token");
            // window.location.href = '/'
        } else {
            if (alert) showAlert(json, form_id, show_toast)
            res(json)
        }
    }).catch((error) => { console.log(error) });
}

export const checkFile = (e) => {
    const fileExt = ['jpeg', 'jpg', 'png'];
    const files = e.target.files[0];
    let ext = files.name.split('.').pop();
    if (!fileExt.includes(ext.toLowerCase())) {
        alert('Please upload file in pdf, jpeg, jpg, png format.');
        return ''
    } else {
        return files
    }
}

export const getWeekDays = (week_day) => {
    let date = new Date(week_day);
    let day = date.toLocaleString('en-us', { weekday: 'long' });
    return day;
}

export const intlTel_phone = (phone_id, country_id, country_code, dropdown = false) => {
    // Initialise the IntlTelInput
    const input = document.querySelector(phone_id);
    let iti_phone = intlTelInput(input, {
        formatOnDisplay: false,
        separateDialCode: true,
        allowDropdown: dropdown
    });
    // Load the utils JS
    window.intlTelInputGlobals.loadUtils("/static/js/intlTelInput-utils.js")
    // Create Instance of IntlTelInput
    var iti = window.intlTelInputGlobals.getInstance(document.querySelector(phone_id));

    if (country_id) {
        // Populate the country dropdown
        for (var i = 0; i < window.intlTelInputGlobals.getCountryData().length; i++) {
            var country = window.intlTelInputGlobals.getCountryData()[i];
            var optionNode = document.createElement("option");
            optionNode.value = country.iso2;
            var textNode = document.createTextNode(country.name);
            optionNode.appendChild(textNode);
            document.querySelector(country_id).appendChild(optionNode);
        }

        $(country_id).val(country_code);
        iti.setCountry(country_code);

        $(country_id).on('change', function () {
            iti.setCountry(this.value);
            validatePhone(iti_phone, phone_id, false)
        });
    }

    input.addEventListener('keyup', function () {
        validatePhone(iti_phone, phone_id, false)
    });

    input.addEventListener('change', function () {
        validatePhone(iti_phone, phone_id, false)
    });

    return iti_phone
}

export const validatePhone = (iti_phone, phone_id, showErrMsg = true) => {
    let errorMap = ["Phone number is invalid.", "Country code is invalid.", "Phone number is too short.", "Phone number is too long.", "Phone number is invalid."];

    $(phone_id).removeClass('is-invalid');
    $(phone_id).siblings('.invalid-feedback').remove();
    if (!iti_phone.isValidNumber()) {
        var errorCode = iti_phone.getValidationError()
        $(phone_id).addClass('is-invalid')
        if (showErrMsg) $(phone_id).after(`<div class="invalid-feedback">${errorMap[errorCode] ? errorMap[errorCode] : 'Phone number is invalid.'}</div>`)
        return false;
    } else {
        return true;
    }
}

export const reactSelectValidation = (fields) => {
    let error = false;
    fields.forEach(element => {
        if (element.value.length) {
            document.getElementById(element.id).classList.add("is-valid");
            document.getElementById(element.id).classList.remove("is-invalid");
        } else {
            document.getElementById(element.id).classList.add("is-invalid");
            document.getElementById(element.id).classList.remove("is-valid");
            error = true;
        }
    });
    return !error
}

export const generateId = (length) => {
    let result = '';
    let characters = 'abcdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ1234567890';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export const initialFormState = (formId, reset = true) => {
    [...document.querySelectorAll(`#${formId} .form-control, #${formId} .form-select, #${formId} .invalid-feedback`)].forEach((elm) => {
        elm.classList.remove('is-invalid');
        elm.classList.remove('is-valid');
        elm.classList.remove('d-block');
    });
    document.getElementById(formId).classList.remove('was-validated')
    if (reset) document.getElementById(formId).reset()
}

export const scrollbarSetToTop = () => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: 'auto',
    });
}

export const downloadFile = (blobImg, name) => {
    let url = window.URL.createObjectURL(new Blob([blobImg]));
    let ext = _.split(blobImg.type, '/')[1]
    if (ext.match('spreadsheetml')) {
        ext = 'xlsx'
    } else if (ext.match('wordprocessingml')) {
        ext = 'docx'
    }
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name + '.' + ext);
    document.body.appendChild(link);
    link.click();
}
export const decryptCode = (code) => {
    let str = '';
    // code = code.split("").reverse().join("");
    code = _.join(_.reverse(_.split(code, '')), '');
    code = code.match(/.{1,3}/g)
    for (let i = 0; i < _.size(code); i++) {
        if (i % 2 !== 0) {
            str += String.fromCharCode(code[i])
        }
    }
    return str.split("").reverse().join("");
}

export const loadingData = (active) => {
    if (active) {
        document.querySelector('body').classList.add('loading-data');
    } else {
        document.querySelector('body').classList.remove('loading-data');
    }
}

export const ButtonSpinner = props => {
    const { t } = useTranslation();
    return (
        <>
            {
                props.load ?
                    <div className="spinner-border spinner-border-sm mx-3" role="status">
                        <span className="visually-hidden">{t("loading")}</span>
                    </div>
                    : props.btnName
            }
        </>
    )
}

export const noImageHandle = (e, img = null) => {
    e.target.src = img ?? "/images/no-image.png";
}

export const srcToBase64 = async (url, res) => {
    await fetch(`https://cors-fix.web.app/v1?url=${url}`)
        .then(data => data.blob().then(blob => {
            const reader = new FileReader();
            reader.onloadend = () => {
                res(reader.result)
            };
            reader.readAsDataURL(blob);
        }));
}

export const downloadBase64File = (url, name = 'attachment') => {
    let link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
}

export const showPassword = (e, id) => {
    let btn = e.target;
    let ele = document.getElementById(id);
    if (ele.getAttribute('type') === 'password') {
        ele.setAttribute('type', 'text');
        btn.innerHTML = '<i class="bi-eye-slash fs-5 pe-none"></i>'
    } else {
        ele.setAttribute('type', 'password');
        btn.innerHTML = '<i class="bi-eye fs-5 pe-none"></i>'
    }
}

export const encodedText = (text, app_key) => {
    // app_key = Laravel APP_KEY in Laravel
    let iv = CryptoJS.lib.WordArray.random(16)
    let key = CryptoJS.enc.Base64.parse(app_key);
    let options = { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 };
    let encrypted = CryptoJS.AES.encrypt(JSON.stringify(text), key, options).toString();
    iv = CryptoJS.enc.Base64.stringify(iv);
    let result = { iv: iv, value: encrypted, mac: CryptoJS.HmacSHA256(iv + encrypted, key).toString() };
    result = JSON.stringify(result);
    result = CryptoJS.enc.Utf8.parse(result);
    result = CryptoJS.enc.Base64.stringify(result);
    // $decrypted = json_decode(decrypt(result, false)) // In Laravel
    return result;
}

export const decodedText = (text, app_key) => {
    // app_key = Laravel APP_KEY in Laravel
    // $encrypted = encrypt("Akash", false);   // In Laravel
    let encrypted = window.atob(text);
    encrypted = JSON.parse(encrypted);
    let iv = CryptoJS.enc.Base64.parse(encrypted.iv);
    let key = CryptoJS.enc.Base64.parse(app_key);
    let decrypted = CryptoJS.AES.decrypt(encrypted.value, key, { iv: iv });
    decrypted = decrypted.toString(CryptoJS.enc.Utf8);
    return decrypted;
}
export const getTimeFromDateString = (dateString) => {
    const date = new Date(dateString);
    const currentDate = new Date();

    if (date.toDateString() === currentDate.toDateString()) {
        // Display time only if it's the current day
        const options = {
            hour: 'numeric',
            minute: 'numeric',
            hour12: true
        };
        return date.toLocaleString([], options);
    } else if (date.getFullYear() === currentDate.getFullYear()) {
        // Display month and day if it's the current year
        const options = {
            month: 'long',
            day: 'numeric'
        };
        return date.toLocaleString([], options);
    } else {
        // Display full date for previous years
        const options = {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        };
        return date.toLocaleString([], options);
    }
};


export const chatifyfetcher = async (url, method, data, token, process, res, abort_signal = false) => {
    let headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'localization': localStorage.getItem('localization') ?? 'en'
    }

    headers = process ? { ...headers, 'contentType': false, 'processData': false } : { ...headers, 'Content-Type': 'application/json' }
    if (token) headers = { ...headers, 'Authorization': 'Bearer ' + localStorage.getItem('recruitment-token') }

    let request = { 'method': method, 'headers': headers }
    if (data) request = { ...request, 'body': process ? data : JSON.stringify(data) }
    if (abort_signal) request = { ...request, 'signal': abort_signal }

    await fetch(`${CHATIFY_API_URL}${url}`, request).then((response) => response.json()).then((json) => {
        if (json.message === "Unauthenticated." || json.type === "block_user") {
            if (json.type === "block_user") localStorage.setItem("block_user", JSON.stringify(json))
            localStorage.removeItem("recruitment-token");
            window.location.href = '/'
        } else {
            //alert will be here
            res(json)
        }
    }).catch((error) => { console.log(error) });
}

export const downloadFileFromServer = async (url, method, data, token, res) => {
    let headers = {
        'Accept': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json',
        'localization': localStorage.getItem('localization') ?? 'en'
    }

    if (token) {
        let TOKEN = localStorage.getItem('recruitment-token')
        headers = { ...headers, 'Authorization': 'Bearer ' + TOKEN }
    }

    let request = {
        'method': method,
        'headers': headers,
    }

    if (data) {
        request = { ...request, 'body': JSON.stringify(data) }
    }

    await fetch(`${CHATIFY_API_URL}${url}`, request).then((response) => response.blob()).then((json) => {
        res(json)
    }).catch((error) => { console.log(error) });
}

export const stringTrim = (string, length) => {
    var trimmedString =
        string.length > length ? string.substring(0, length - 3) + "..." : string;
    return trimmedString;
};


function byteToHex(byte) {
    return ('0' + byte?.toString(16))?.slice(-2);
}


export const generateRandomHashKey = (len = 40) => {
    var arr = new Uint8Array(len / 2);
    window?.crypto?.getRandomValues(arr);
    return Array?.from(arr, byteToHex).join("");
}

export const dobMinYear = () => {
    let d = new Date().getFullYear() - 18
    return `12-31-${d}`;
}

export const calculateDuration = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Calculate the difference in milliseconds
    const durationInMilliseconds = end - start;

    // Convert milliseconds to years, months, and days
    const years = Math.floor(durationInMilliseconds / (365 * 24 * 60 * 60 * 1000));
    const months = Math.floor((durationInMilliseconds % (365 * 24 * 60 * 60 * 1000)) / (30 * 24 * 60 * 60 * 1000));
    const days = Math.floor((durationInMilliseconds % (30 * 24 * 60 * 60 * 1000)) / (24 * 60 * 60 * 1000));
    return { years, months, days };
};
export const formatDuration = ({ years, months, days }) => {
    let result = '';

    if (years > 0) {
        result += `${years} ${years === 1 ? 'year' : 'years'}`;
    }

    if (months > 0) {
        result += `${result.length > 0 ? ' ' : ''}${months} ${months === 1 ? 'month' : 'months'}`;
    }

    if (days > 0) {
        result += `${result.length > 0 ? ' ' : ''}${days} ${days === 1 ? 'day' : 'days'}`;
    }

    return result.length > 0 ? result : '0 days';
};

export const getDateDurationInDays = (start_date, end_date) => {
    const a = moment(start_date);
    const b = moment(end_date);
    var years = a.diff(b, 'year');
    b.add(years, 'years');

    const noOfDaysInb = b.daysInMonth();
    const noOfDaysIna = a.daysInMonth();
    let months = 0;
    if (noOfDaysInb > noOfDaysIna) {
        months = b.diff(a, "months");
        a.add(months, "months");
    } else {
        months = a.diff(b, 'months');
        b.add(months, 'months');
    }
    var days = a.diff(b, 'days');
    return (Math.abs(years) === 0 ? "" : Math.abs(years) + (Math.abs(years) === 1 ? " year " : " years ")) +
        (Math.abs(months) === 0 ? "" : Math.abs(months) + (Math.abs(months) === 1 ? " month " : " months ")) +
        (Math.abs(days) === 0 ? "" : Math.abs(days) + (Math.abs(days) === 1 ? " day " : " days"));

}

export const meetingStatusUpdate = (MEETING_OVER, meeting_id, meeting_password, role, status) => {
        fetchData(`${MEETING_OVER}/${meeting_id}/${status}`, "POST", '', true, true, (res) => {
            if (res.status && res.body === 'in_progress') {
                window.location.href = `/join-call?meeting_id=${encodeURIComponent(meeting_id)}&meeting_password=${encodeURIComponent(meeting_password)}&status=${status}`;
            }
            if (res.status && res.body === 'over') {
                window.location.href = 'meeting';
            }
        });
};

export const trackMeetingLocationAddress = (TRACK_IP_ADDRESS,locationInfo,company_id,candidate_id,latitude, longitude,MEETING_OVER, meeting_id, meeting_password, role, status) => {
    let data = {locationInfo,company_id,candidate_id,latitude, longitude};
    fetchData(`${TRACK_IP_ADDRESS}`, "POST", data, true, false, (res) => {
        if(res.status){
            meetingStatusUpdate(MEETING_OVER, meeting_id, meeting_password, role, status)
        }
    });
};

export const dateFormat = (date, time = false) => {
    if(time){ 
        return moment(date).format('YYYY-MM-DD H:mm a'); 
    }else{ 
        return moment(date).format('YYYY-MM-DD'); 
    }
    
}