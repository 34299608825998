import { downloadBase64File, noImageHandle, srcToBase64 } from "../../../components/Helpers/Helper"
import { useTranslation } from "react-i18next";

const MailDetails = ({ details, setDetails, setReply }) => {
    const { t } = useTranslation();
    const downloadMedia = (url) => {
        srcToBase64(url, (res) => {
            downloadBase64File(res)
        })
    }

    return (
        <>
            <div className="mail-right px-3">
                <i className="fa-solid fa-arrow-left" role="button" onClick={() => setDetails(false)}></i>
                <h1 className="text-center">{details.subject}</h1>
                <div className="accordion" id="accordionExample">
                    {details.all_mail && details.all_mail.length > 0 && details.all_mail.map((m, key) => {
                        return (
                            <div className="accordion-item" key={key}>
                                <h2 className="accordion-header">
                                    <button className={`accordion-button bg-light`} type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne_${key}`} aria-expanded="false" aria-controls="collapseOne">
                                        <span className="extra-bold me-2">{m.subject}</span> <span class="d-inline-block text-truncate" style={{maxWidth:"calc(100% - 205px)"}}></span> <span className="accordian_email_date">{m.mail_date}</span>
                                    </button>
                                </h2>
                                <div id={`collapseOne_${key}`} className={`accordion-collapse collapse ${(details.all_mail.length === key + 1) && 'show'}`} aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                    <div className={`accordion-body ${m.status === 'failed' && 'bg-danger'}`}>
                                        <div dangerouslySetInnerHTML={{ __html: m.body }}></div>
                                        <div className="m-2 email_img">
                                            {m.attachments && m.attachments.length > 0 && m.attachments.map((a, i) => {
                                                return (
                                                    <div key={i} className="position-relative m-2">
                                                        <img src={a} alt="Attachments" onError={noImageHandle} />
                                                        <i className="fa-solid fa-download ms-3 position-absolute top-0" role="button" onClick={() => downloadMedia(a)}></i>
                                                    </div>
                                                )
                                            })}
                                        </div>
                                        <span className="mt-2">{m.mail_date}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
                <button type="button" data-bs-toggle="modal" data-bs-target="#composeModal" onClick={() => setReply(details)} className="btn btn-primary js-sweetalert mt-2" title="Reply">{t("reply")}</button>
            </div>
        </>
    )
}

export default MailDetails
