import { useTranslation } from "react-i18next";
import { ModalSection } from "../../../components/Helpers/ModalHelper";
import { InputField, ReactSelect, SubmitButton, removeError, showPassword } from "../../../components/Helpers/FormHelper";
import { useEffect, useState } from "react";
import { EMAIL_SETTING_CREATE, IMAP_DATA, TEST_MAIL } from "../../../components/Helpers/ApiRoutes";
import { fetchData, validateForm } from "../../../components/Helpers/Helper";
import { Tooltip, OverlayTrigger } from 'react-bootstrap';

const MailSetting = ({ completeEmailSetting, setCompleteEmailSetting }) => {
    const { t } = useTranslation();
    const [mailPort] = useState([{ label: "587", value: "587" }, { label: "465", value: "465" }]);
    const [mailIncryption] = useState([{ label: "tls", value: "tls" }, { label: "ssl", value: "ssl" }]);
    const [mailSetting, setMailSetting] = useState({ username: "", password: "", mail_port: { label: "587", value: "587" }, mail_driver: "smtp", mail_host: "smtp.gmail.com", mail_encryption: { label: "tls", value: "tls" } });
    const [tinyloader, setTinyloader] = useState(false);

    useEffect(() => {
        fetchData(IMAP_DATA, 'GET', '', true, false, (res) => {
            if (res && res.status && res.body && res.body.id) {
                let port = mailPort.filter((item) => item.value === res.body.mail_port)
                let encryption = mailIncryption.filter((item) => item.value === res.body.mail_encryption)

                setMailSetting({
                    id: res.body.id,
                    username: res.body.username,
                    password: res.body.password,
                    mail_driver: res.body.mail_driver,
                    mail_host: res.body.host,
                    mail_port: port[0] ?? { label: "587", value: "587" },
                    mail_encryption: encryption[0] ?? { label: "tls", value: "tls" },
                })
            }
        })
    }, [mailPort, mailIncryption])

    const sendEmail = () => {
        setTinyloader(true)
        let test_mail = document.getElementById("test_mail")
        fetchData(TEST_MAIL, 'POST', { email: test_mail.value }, true, false, (res) => {
            setTinyloader(false)
            if (res.status) {
                test_mail.value = ""
            }
        })
    }

    const submitForm = (e) => {
        if (validateForm(e, "mail-setting-form")) {
            let formdata = new FormData(document.getElementById('mail-setting-form'))
            fetchData(EMAIL_SETTING_CREATE, 'POST', formdata, true, true, (res) => {
                if (res.status) {
                    setCompleteEmailSetting(true)
                }
            })
        }
    }

    return (
        <>
            <ModalSection modal_id="mailSettingModal" close_btn_id="closeMailSettingModal" title={t("mail_setting")} size="xl" footer={<>
                <div className="input-group mb-0">
                    <div className="input-group-prepend ">
                        <span className="input-group-text">{t('test_mail')}</span>
                    </div>
                    <input type="text" className="form-control gallery-form" placeholder="example@mail.com" name="test_mail" id="test_mail" />
                    <button type="button" className="btn btn-primary" onClick={sendEmail} disabled={tinyloader || !completeEmailSetting}>
                        {tinyloader ? <div className="spinner-border spinner-border-sm mx-2" role="status"><span className="visually-hidden">{t("loading")}</span></div> : "Send"}
                    </button>
                </div>
            </>}>
                <form id="mail-setting-form" action="#" method="post" className="row g-3 needs-validation" noValidate>
                    <InputField col={2} name="username" label={t("form.username")} placeholder={t("form.username")} autoComplete="off" defaultValue={mailSetting.username} required />
                    <div className={`mt-2 mx-0 px-2 col-sm-md col-lg-6`}>
                        <label htmlFor="password" className={"px-1"}>
                            {t("form.password")}<sup className='text-danger fw-bold fs-15px'>*</sup>
                        </label>
                        <OverlayTrigger placement="top" overlay={<Tooltip> If you're using a Gmail account then please click this icon to generate a password. </Tooltip>}>
                            <a href="https://support.google.com/mail/answer/185833?hl=en" target="_blank" rel="noreferrer"><i className="text-secondary bi bi-info-circle-fill"></i></a>
                        </OverlayTrigger>
                        <div className="position-relative">
                            <input type="password" className="form-control" id="password" name="password" autoComplete="off" placeholder={t("form.password")} onChange={removeError} required />
                            <div className="invalid-feedback">{t('validation.required', t("form.password"))}</div>
                            <div role="button" className="password-eye" onClick={(e) => showPassword(e, 'password')}>
                                <i className="bi-eye fs-5 pe-none"></i>
                            </div>
                        </div>
                    </div>
                    {/* <InputField col={2} type="password" name="password" label={t("form.password")} defaultValue={mailSetting.password} required /> */}
                    <ReactSelect size="col-sm-6 mb-3" name="mail_port" label={t("form.mail_port")} required value={mailSetting.mail_port} options={mailPort} onChange={(e) => setMailSetting(prevState => ({ ...prevState, mail_port: e }))} />
                    <ReactSelect size="col-sm-6 mb-3" name="mail_encryption" label={t("form.mail_encryption")} required value={mailSetting.mail_encryption} options={mailIncryption} onChange={(e) => setMailSetting(prevState => ({ ...prevState, mail_encryption: e }))} />
                    <InputField col={2} name="mail_host" label={t("form.mail_host")} defaultValue={mailSetting.mail_host} placeholder={t("form.mail_host")} required />
                    <InputField col={2} name="mail_driver" label={t("form.mail_driver")} defaultValue={mailSetting.mail_driver} placeholder={t("form.mail_driver")} required />
                    <SubmitButton noOffset={1} load={false} title={t("form.update")} action={(e) => submitForm(e)} />
                </form>
            </ModalSection>
        </>
    )
}

export default MailSetting
