import { useTranslation } from "react-i18next"
import Select from 'react-select'
import { stringTrim } from "../Helpers/Helper";
import { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import moment from "moment";

export const AdvanceFilterModal = (props) => {
    const { t } = useTranslation();

    return (
        <div className={`modal fade ${props.class}`} id={props.modal_id ?? "advance-filter-modal"} tabIndex="-1" aria-labelledby={props.modal_id} aria-hidden="true" data-bs-backdrop="static" data-attr={props.data_attr ?? null}>
            <div className={`modal-dialog modal-${props.size ?? 'xl'}`}>
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title" title={props.title ? props.title : t("Advance Filter")}> {props.title ? stringTrim(props.title, 30) : t("Advance Filter")} </h5>
                        <button id={props.close_btn_id ?? null} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.closeModal ?? null}></button>
                    </div>
                    <div className={`modal-body p-sm-4`} id={props.modal_body_id ?? null}>{props.children}</div>
                    <div className="modal-footer bg-light">
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={props.filter ?? null}>{props.submitTitle ?? t('Filter')}</button>
                        <button type="button" id="reset-advance-filter" className="btn btn-warning" data-bs-dismiss="modal" onClick={props.reset ?? null}>{props.resettTitle ?? t('Reset')}</button>
                        <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal" onClick={props.closeModal ?? null}>{t("Cancel")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const FilterButton = (props) => {
    const { t } = useTranslation();

    return (
        <div className="border border-1 border-black rounded p-1">
            <style jsx="true">{`
            .p-sm{
                padding: 0.2rem 0.4rem;
            }
        `}</style>
            <button title={props.title ?? t('Filter')} className="btn btn-sm btn-outline-secondary me-1 p-sm" data-bs-toggle="modal" data-bs-target={"#advance-filter-modal"} onClick={props.action ?? null}>
                <i className="bi bi-funnel"></i>
            </button>
            <button title={props.title ?? t('Reset')} className="btn btn-sm btn-outline-danger p-sm" onClick={() => document.getElementById("reset-advance-filter").click()}>
                <i className="bi bi-x-circle-fill"></i>
            </button>
        </div>
    )
}

export const InputFilter = (props) => {
    return (
        <div className="mb-3 mx-0 px-2 col-12 col-sm-6 col-md-4 col-lg-3">
            <label htmlFor={props.name} className="form-label">
                {props.label}
            </label>
            <div className="">
                <input type="text" className="form-control" id={props.id ?? props.name} {...props} autoComplete="off" />
            </div>
        </div>
    )
}

export const SelectFilter = (props) => {

    return (
        <div className="mb-3 mx-0 px-2 col-12 col-sm-6 col-md-4 col-lg-3">
            <label htmlFor={props.name} className="form-label">
                {props.label}
            </label>
            <Select className="react-select customSelect" id={props.id ?? props.name} {...props} />
        </div>
    )
}

export const SwitchFilter = (props) => {
    return (
        <div className="mb-3 mx-0 px-2 col-12 col-sm-6 col-md-4 col-lg-3">
            <label className="form-check-label cursor_pointer" htmlFor={props.id ?? props.name}>{props.label}</label>
            <div className="form-check form-switch mt-1" style={{ fontSize: "20px" }}>
                <input className="form-check-input cursor_pointer" type="checkbox" role="switch" id={props.id ?? props.name} {...props} />
            </div>
        </div>
    )
}

export const DateRangeFilter = (props) => {
    const { t } = useTranslation();
    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    useEffect(() => {
        if (props.reset) {
            setDateRange([null, null]);
        }
    }, [props.reset])

    const dateFilter = (date) => {
        setDateRange(date);
        if (date[0] && date[1]) {
            let query = `start_date=${moment(date[0]).format("DD-MM-YYYY")}&end_date=${moment(date[1]).format("DD-MM-YYYY")}&`;
            props.dateRangeQuery(query);
        }
        if (!date[0] && !date[1]) {
            props.dateRangeQuery("");
        }
    }

    return (<>
        <style jsx="true">{`
            .mimw-300px{
                min-width: 300px;
            }
        `}</style>
        <DatePicker
            isClearable
            peekNextMonth
            showYearDropdown
            showMonthDropdown
            autoComplete="off"
            dropdownMode="select"
            id="date-range-filter"
            dateFormat="dd MMM yyyy"
            className={`form-control mimw-300px`}
            placeholderText={t('Select Date Range')}
            selectsRange
            endDate={endDate}
            startDate={startDate}
            onChange={dateFilter}
            maxDate={new Date()}
        />
    </>)
}