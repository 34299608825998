import { useEffect, useState } from "react";
import { DYTE_MEETING_LIST } from "../../components/Helpers/ApiRoutes";
import DataTables, { reloadDataTable } from "../../components/Helpers/Datatables";
import { createRoot } from "react-dom/client";
import { ViewButtonModal } from "../../components/Helpers/ButtonHelper";
import { ModalSection } from "../../components/Helpers/ModalHelper";


const DyteMeetngs = () => {
    const [reload] = useState()
    const [data, setData] = useState()
    const [dt] = useState({
        dt_url: DYTE_MEETING_LIST,
        dt_name: "dyte-meeting-list",
        dt_column: [
            { data: "DT_RowIndex", name: "id", title: "*", sortable: false, searchable: true, className: "noExport" },
            { data: "host_id", name: "host_id", title: "Host Id" },
            { data: "meeting_title", name: "meeting_title", title: "Meeting Title" },
            { data: "meeting_id", name: "meeting_id", title: "Meeting Id" },
            { data: "meeting_status", name: "meeting_status", title: "Meeting Status" },
            { data: "meeting_recording", name: "meeting_recording", title: "Meeting Recording" },
            { data: "meeting_date", name: "meeting_date", title: "Date" },
            { data: "meeting_time", name: "meeting_time", title: "Time" },
            { data: "id", name: "id", title: "Action", sortable: false, searchable: true, className: "noExport" },
        ],
        dt_column_defs: [
            {
                targets: 8,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(<>
                        <div className="d-flex text-nowrap">
                            <ViewButtonModal modal_id="#itemDeleteModal" action={() => setData(records)} />
                        </div>
                    </>)
                }
            },
        ],
    });

    const [meeting_dt] = useState({
        dt_url: DYTE_MEETING_LIST,
        dt_name: 'dyte-participant-list',
        dt_order: [[0, 'desc']],
        dt_column: [
            { data: "DT_RowIndex", name: 'id', title: "SR. NO", searchable: false, sortable: false },
            { data: "name", name: 'name', title: " Name" },
            { data: "id", title: "Action", sortable: false, searchable: false, class: "text-nowrap noExport", width: 160 }
        ],

    })
 
    useEffect(() => {
        reloadDataTable(meeting_dt, DYTE_MEETING_LIST);
    }, [meeting_dt, reload]);

    useEffect(() => {
        reloadDataTable(dt, DYTE_MEETING_LIST);
    }, [dt, reload]);




    return (
        <>
            <DataTables dt={dt} dt_name="dyte-meeting-list" />
            <ModalSection modal_id="itemDeleteModal" title="Dyte Meeting" size="xl">
                <div>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Id</th>
                                <th scope="col">Meeting Id</th>
                                <th scope="col">Participant Id</th>
                                <th scope="col">Participant Code</th>
                                <th scope="col">Participant Name</th>
                                <th scope="col">Role</th>
                            </tr>
                        </thead>
                        {data && data.get_participants.map((val, index) => {
                            return (
                                <tbody key={index}>
                                    <tr>
                                        <td>{val.id}</td>
                                        <td>{val.meeting_id}</td>
                                        <td>{val.participant_id}</td>
                                        <td>{val.participant_code}</td>
                                        <td>{val.participant_name}</td>
                                        <td>{val.role}</td>
                                    </tr>
                                </tbody>
                            )
                        })}
                    </table>
                </div>
            </ModalSection>
        </>
    );
};

export default DyteMeetngs;
