import ReactDom from "react-dom";
import React, { memo, useEffect } from "react";
import { useDyteClient } from '@dytesdk/react-web-core';
import { DyteMeeting } from '@dytesdk/react-ui-kit';
import Draggable from 'react-draggable';
import { Resizable } from "re-resizable";
import { useState } from "react";
import { isCandidate } from "../Helpers/RoleHelper";

const JoinDyteMeeting = ({ data, setContext }) => {
    const [meeting, initMeeting] = useDyteClient();
    const [loader, setLoader] = useState(true);

    useEffect(() => {
        if (data) {
            setLoader(true)
            initMeeting(undefined)

            setTimeout(() => {
                setLoader(false);
                initMeeting({
                    authToken: isCandidate === 'candidate' ? data?.get_participant?.auth_token : data?.get_host?.auth_token,
                    defaults: { audio: false, video: false },
                });
            }, 1000);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    const closeMeeting = () => {
        setContext(prevState => ({ ...prevState, dytemeeting: null }))
    }

    return (<>
        <style jsx="true">{`.handle:hover {cursor: move}`}</style>
        {data && ReactDom.createPortal(<>
            <Draggable axis="both" handle=".handle" defaultPosition={{ x: 0, y: 50 }} grid={[25, 25]} scale={1}
                bounds={{ left: 0, top: 0, right: 1500, bottom: 800 }} onStart={(e) => { }} onDrag={(e) => { }} onStop={(e) => { }}>
                <Resizable style={{ display: "flex" }} defaultSize={{ width: window.innerWidth / 2, height: window.innerHeight / 2 }} enable={{ top: false, right: true, bottom: true, left: false, topRight: false, bottomRight: true, bottomLeft: false, topLeft: false }}>
                    <div className="row w-100 border bg-light">
                        <div className="col-12 handle position-relative text-center" style={{ zIndex: 1, background: "black", height: "fit-content", top: "-47px", left: "12px" }}>
                            <p className="text-light">{data?.meeting_title} <span className="text-danger ms-auto" onClick={closeMeeting}>Close</span></p>
                        </div>
                        <div className="col-12">
                            <div className="h-100 w-100 rounded">
                                {loader ? <div className="text-center"><div className="spinner-border spinner-border-sm" role="status"></div></div> : <DyteMeeting meeting={meeting} />}
                            </div>
                        </div>
                    </div>
                </Resizable>
            </Draggable>
        </>, document.getElementById("dyte-meeting"))}
    </>)
};
export default memo(JoinDyteMeeting);
