import { useEffect, useState } from "react";
import DataTables, { reloadDataTable } from "../Helpers/Datatables";
import { createRoot } from 'react-dom/client'
import { MEETING_OVER,TRACK_IP_ADDRESS} from "../../components/Helpers/ApiRoutes"
import _ from "lodash";
import { dateFormat,trackMeetingLocationAddress } from "../../components/Helpers/Helper"

const CandidateMeetingList = (props) => {
    const [dt] = useState({
        dt_url: props.listUrl,
        dt_name: 'candidate-meetings',
        dt_column: [
          { data: "DT_RowIndex", name:'id', title: "SR. NO", sortable: false },
          { data: "meeting_title", title: 'Meeting Title', name:"meeting_title", sortable: true, searchable: true },
          { data: "meeting_type", title: 'Meeting Type', name: "meeting_type", sortable: true, searchable: true  },
          { data: "meeting_id", title: 'Meeting Id', name: "meeting_id", sortable: true, searchable: true , class: "wrap" },
          { data: "meeting_password", title: 'Meeting Password', sortable: true, searchable: true , name: "meeting_password	" },
          { data: "meeting_duration", title: 'Meeting Duration', sortable: false, searchable: false,  name: "meeting_duration" },
          { data: "status", title: 'Status', name: "status" },
          { data: "start_date", title: 'Meeting Date', name: "start_date" },
          { data: "start_time", title: 'Meeting Time', name: "start_time" },
          { data: "id", title: "Action", sortable: false, searchable: false },
          ],
          dt_column_defs: [
            {
              targets: 5,
              createdCell: (td, cellData, records, row, col) => {
                createRoot(td).render(<>
                  <div className="d-flex text-nowrap">
                    <span>{records.meeting_duration} minutes</span>
                  </div>
                </>)
              }
            },
            {
              targets: 6,
              createdCell: (td, cellData, records, row, col) => {
                createRoot(td).render(<>
                  <div className="d-flex text-nowrap">
                    <span className={`badge badge-${records.status === "scheduled" ? 'info' : records.status === "in_progress" ? 'success' :
                      records.status === "not_attend" ? 'primary' : records.status === "over" ? 'danger' : 'danger'}`}>{_.upperFirst(records.status)}</span>
                  </div>
                </>)
              }
            },
            {
              targets: 7,
              createdCell: (td, cellData, records, row, col) => {
                createRoot(td).render(<>
                    <span className={`${records.start_date === dateFormat() ? 'badge badge-white bg-success' : ''}`}>{records.start_date}</span>  
                </>)
              }
            },
            {
              targets: 9,
              createdCell: (td, cellData, records, row, col) => {
                createRoot(td).render(<>
                  <div className="d-flex text-nowrap">
                    <button onClick={() => (records.status === 'scheduled' || records.status === 'in_progress') ? statusUpdate(records.meeting_id, records.meeting_password,records.company_id,records.candidate_id) : ''} className="btn btn-primary" disabled={records.status === 'scheduled' || records.status === 'in_progress' ? false : true}>
                      Lunch Meeting
                    </button>
                  </div>
      
                </>)
              }
            },
          ],
    })

    async function getLocationInfo(latitude, longitude) {
      try {
        const response = await fetch(
          `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`
        );
    
        if (!response.ok) {
          throw new Error(`Failed to fetch location information: ${response.statusText}`);
        }
    
        const data = await response.json();
        const address = data.address || {};
        const city = address.city || address.village || address.town;
        const state = address.state || address.region;
        const country = address.country ;
        const postcode = address.postcode || address.region;
        const place_id = data.place_id;
        const full_address = data.display_name;
        const ipAddress ='';
    
        return { city, state, country, postcode, place_id,full_address,ipAddress };
      } catch (error) {
        console.error('Error getting location information:', error);
        throw error;
      }
    }
  
    const getLocation = (company_id,candidate_id,MEETING_OVER, meeting_id, meeting_password, role, status) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const { latitude, longitude } = position.coords;
            getLocationInfo(latitude, longitude)
            .then((locationInfo) => {
              trackMeetingLocationAddress(TRACK_IP_ADDRESS,locationInfo,company_id,candidate_id,latitude, longitude,MEETING_OVER, meeting_id, meeting_password, role, status);
            })
            .catch((error) => {
              console.error('Error fetching location information:', error);
            });
          },
          (error) => {
            console.error('Error getting location:', error.message);
          }
        );
      } else {
        console.error('Geolocation is not supported by this browser.');
      }
    };


    const statusUpdate = (meeting_id,meeting_password,company_id,candidate_id) => {
      let role = (process.env.REACT_APP_ROLE === 'company' ? 1 : 0)
      let status= 'in_progress'
      getLocation(company_id,candidate_id,MEETING_OVER, meeting_id, meeting_password, role, status)
      }

    useEffect(() => {
        reloadDataTable(dt, props.listUrl)
    }, [dt, props.listUrl])

    return (

        <>
            <DataTables dt={dt} dt_name="candidate-meetings" />
        </>

    )
}

export default CandidateMeetingList