import { useTranslation } from "react-i18next"
import { noImageHandle } from "../Helpers/Helper";
import { NavLink } from "react-router-dom";

const FrontendFooter = () => {
    const { t } = useTranslation();

    return (
        <>
            <footer className="footer-area pt-100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 mb-3">
                            <div className="footer-widget">
                                <div className="footer-logo mb-4">
                                    <NavLink to="/">
                                        <img src="/img/light-logo150x40.svg" alt="logo" height="45" onError={noImageHandle} />
                                    </NavLink>
                                </div>
                                <p>{t("footer_short_description")}</p>
                                <div className="footer-social">
                                    <NavLink to="#"><i className="bx bxl-facebook"></i></NavLink>
                                    <NavLink to="#"><i className="bx bxl-twitter"></i></NavLink>
                                    <NavLink to="#"><i className="bx bxl-pinterest-alt"></i></NavLink>
                                    <NavLink to="#"><i className="bx bxl-linkedin"></i></NavLink>
                                </div>
                            </div>
                        </div> 
                        <div className="col-lg-8 offset-lg-1">
                            <div className="row">
                                <div className="col-lg-4 col-sm-6 mb-3">
                                    <div className="footer-widget pl-60">
                                        <h3>{t("for_candidate")}</h3>
                                        <ul>
                                            <li>
                                                <NavLink to="/dashboard"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.account")}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/faq"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.faq")}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/privacy-policy"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.privacy_policy")}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/terms"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.terms")}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/blog"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.blogs")}</NavLink>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6 mb-3">
                                    <div className="footer-widget pl-60">
                                        <h3>{t("quick_links")}</h3>
                                        <ul>
                                            <li>
                                                <NavLink to="/"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.home")}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/about-us"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.about")}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/jobs"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.jobs")}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="/contact-us"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.contact")}</NavLink>
                                            </li>
                                            <li>
                                                <NavLink to="pricing"><i className="bx bx-chevrons-right bx-tada"></i> {t("nav.pricing")}</NavLink>
                                            </li>
                                        
                                        </ul>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-6">
                                    <div className="footer-widget footer-info">
                                        <h3>{t("information")}</h3>
                                        <ul>
                                            <li>
                                                <div className="d-flex gap-1 hover-efect">
                                                <span>
                                                    <i className="bx bxs-phone"></i>{" "}
                                                </span>
                                                <a href={`tel:${t("site.phone")}`}>{t("site.phone")}</a>
                                                </div>
                                            </li>
                                            <li>
                                            <div className="d-flex gap-1 hover-efect">
                                                <span>
                                                    <i className="bx bxs-envelope"></i>{" "}
                                                </span>
                                                <a href={`mailto:${t("site.email")}`}>{t("site.email")}</a>
                                                </div>
                                            </li>
                                            <li>
                                            <div className="d-flex gap-1 hover-efect">
                                                <span>
                                                    <i className="bx bx-location-plus"></i>{" "}
                                                </span>
                                                {t("site.address")}
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <button id="goToTopButton"><i className="fa-solid fa-arrow-up"></i></button> */}
            </footer>
            <div className="copy-right">
                <p className="text-center">Copyright &copy; 2023 Xipe Tech. All Rights Reserved. </p>
            </div>
        </>
    )
}

export default FrontendFooter