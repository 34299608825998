import { useTranslation } from "react-i18next";

const SideMenu = ({ mailBox, count, onMenuChange, completeEmailSetting }) => {
    const { t } = useTranslation();
    return (
        <>
            <div className="mail-left collapse" id="email-nav">
                <div className="mail-compose m-b-20"><button disabled={!completeEmailSetting} className="btn btn-block btn-primary" data-bs-toggle="modal" data-bs-target="#composeModal">{t("compose")}</button></div>
                <div className="mail-side">
                    <ul className="nav cursor-pointer">
                        <li className={mailBox === 'inbox' ? 'bg-primary px-2 rounded text-white' : 'px-2'} onClick={() => onMenuChange('inbox')} role="button">
                            <i className="fa fa-envelope me-2 rtl_ms_2" aria-hidden="true"></i>{t("inbox")}{count && count.inbox > 0 && <span className="badge badge-primary float-right mt-2 d-block">{count.inbox}</span>}
                        </li>
                        <li className={mailBox === 'sent' ? 'bg-primary px-2 rounded text-white' : 'px-2'} onClick={() => onMenuChange('sent')} role="button">
                            <i className="fa fa-paper-plane me-2 rtl_ms_2" aria-hidden="true"></i>{t("sent")}{count && count.sent > 0 && <span className="badge badge-info float-right d-block mt-2">{count.sent}</span>}
                        </li>
                        <li className={mailBox === 'spam' ? 'bg-primary px-2 rounded text-white' : 'px-2'} onClick={() => onMenuChange('spam')} role="button">
                            <i className="fa fa-envelope-open me-2 rtl_ms_2" aria-hidden="true"></i>{t("spam")}{count && count.spam > 0 && <span className="badge badge-info float-right d-block mt-2">{count.spam}</span>}
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}

export default SideMenu
