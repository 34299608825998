import { useContext, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";
import _, { now } from "lodash";
import DataTables, { reloadDataTable } from "../Helpers/Datatables";
import { ModalSection } from "../Helpers/ModalHelper";
import { fetchData, noImageHandle, showAlert } from "../Helpers/Helper";
import { ViewButtonModal } from "../Helpers/ButtonHelper";
import JobDetail from "../../modules/Job/Components/JobDetail";
import Context from "../Helpers/Context";
import { useTranslation } from "react-i18next";
import { isCandidate } from "../Helpers/RoleHelper";
import { GET_JOB_DETAILS } from "../Helpers/ApiRoutes";
import { Navigate } from "react-router-dom";
import { Popover } from "bootstrap";
import { FilterButton } from "../AdvanceFilter/Helper";
import { AdvanceFilter } from "../AdvanceFilter/AdvanceFilter";

const JobList = (props) => {
    const [job, setJob] = useState(null);
    const [reload, setReload] = useState(0);
    const [resumes, setResumes] = useState({});
    const [context] = useContext(Context);
    const { t } = useTranslation();
    const [data, setData] = useState({});
    const [applied, setApplied] = useState(false);
    const [dt] = useState({
        dt_url: props.listUrl,
        dt_name: "jobs-list",
        dt_render: () => {
            setTimeout(function () {
                Array.from(document.querySelectorAll('[data-bs-toggle="popover"]')).forEach(popoverNode => new Popover(popoverNode));
            }, 100)
        },
        dt_fixed_column: { left: 2, right: 1 },
        dt_column: [
            { data: "DT_RowIndex", name: "id", title: "", sortable: false, searchable: true, className: "noExport" },
            { data: "logo_url", name: "logo_url", title: "Logo", sortable: false, class: "logo_td", searchable: true },
            { data: "title", name: "title", title: "Job Title", searchable: true },
            { data: "is_remote", name: "is_remote", title: "Remote Job" },
            { data: "min_salary", name: "min_salary", title: "Min Salary" },
            { data: "max_salary", name: "max_salary", title: "Max Salary" },
            { data: "category.name", name: "category.name", title: "Category", sortable: false, searchable: false },
            { data: "company.company_name", name: "company.company_name", title: "Company", sortable: false, searchable: false },
            { data: "experience.name", name: "experience.name", title: "Experience", sortable: false, searchable: false },
            { data: "job_type.name", name: "job_type.name", title: "Job Type", sortable: false, searchable: false },
            { data: "role.name", name: "role.name", title: "Role", sortable: false, searchable: false },
            { data: "salary_type.name", name: "salary_type.name", title: "Salary Type", sortable: false, searchable: false },
            { data: "featured", name: "featured", title: "Other Category", sortable: false, searchable: false },
            { data: "skills", name: "skills", title: "Skills", sortable: false, searchable: false },
            {
                data: "apply_on",
                name: props.from && props.from === "fav" ? "bookmark_candidate_job.created_at" : "applied_jobs.created_at", title: "Date"
            },
            { data: "status", name: "status", title: "Status" },
            { data: "id", name: "id", title: "Action", sortable: false, searchable: true, className: "noExport" },
        ],
        dt_column_defs: [
            {
                targets: 1,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <img src={records.company.logo_url} className="border rounded-circle" style={{ width: "65px", height: "65px" }} alt="logo" onError={noImageHandle} />
                        </>
                    );
                },
            },
            {
                targets: 2,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <div className="text-nowrap text-left ">
                            {/* keep popover open until click somewhere else  */}
                            <span data-bs-content={`${records?.title  ?? 'N/A'}`} data-bs-toggle="popover" data-bs-trigger="focus hover"
                                data-bs-template={`<div class='popover shadow' role='popover'><div class='popover-arrow'></div><div class='popover-inner p-2 '>${records?.title ?? 'N/A'}</div></div>`}
                            >{records?.title?.substring(0, 30) ?? 'N/A'}{records?.title?.length > 30 ? '..' : ''}</span>
                        </div>
                    )
                }
            },
            {
                targets: 3,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <span className={`text-${records.is_remote === 1 ? "success" : "danger"}`}>
                                {records.is_remote === 1 ? "Yes" : "No"}
                            </span>
                        </>
                    );
                },
            },
            {
                targets: 12,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="text-wrap">
                                {records.featured === 1 && <span className="badge badge-warning m-0 me-1 mt-1 pt-2">Featured</span>}
                                {records.highlight === 1 && <span className="badge badge-dark m-0 me-1 mt-1 pt-2">Highlight</span>}
                            </div>
                        </>
                    );
                },
            },
            {
                targets: 13,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <div className="text-wrap" style={{ width: "300px" }}>
                                {records.skills.map(s => <span key={s.value} className="badge badge-success m-0 me-1 mt-1 pt-2">{s.label}</span>)}
                            </div>
                        </>
                    );
                },
            },
            {
                targets: 15,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            <span className={`text-${records.status === "active" ? "success" : "danger"}`}>
                                {_.upperFirst(records.status)}
                            </span>
                        </>
                    );
                },
            },
            {
                targets: 16,
                createdCell: (td, cellData, records, row, col) => {
                    createRoot(td).render(
                        <>
                            {props.from && props.from === "fav" && (
                                <button className="btn btn-sm btn-outline-danger me-2" title="Remove Bookmark" onClick={() => removeToFav(records.slug)}>
                                    <i className="bi bi-bookmark-fill"></i>
                                </button>
                            )}
                            &nbsp;&nbsp;&nbsp;
                            <ViewButtonModal
                                modal_id="#viewJobList"
                                action={() => setJob(records)}
                            />
                        </>
                    );
                },
            },
        ],
        dt_filter: () => createRoot(document.getElementById("dt-custom-filter")).render(<FilterButton />)
    });

    useEffect(() => {
        reloadDataTable(dt, props.listUrl);
    }, [dt, props.listUrl, reload]);

    useEffect(() => {
        if (job) {
            let user_id = context && context.auth && context.auth.id;
            fetchData(`${GET_JOB_DETAILS}/${job.slug}?user_id=${user_id}`, "GET", "", false, true, (res) => {
                if (res.status) {
                    setData(res.body.job);
                    setApplied(res.body.job.applied);
                    // setResumes(res.body.resumes && res.body.resumes.length > 0 ? res.body.resumes[0] : false)
                }
            }
            );
        }
    }, [job, context]);

    const removeToFav = (slug) => {
        fetchData(`job/${slug}/bookmark`, "POST", "", true, false, (res) => {
            if (res.status) setReload(now());
        });
    };

    const applyJob = () => {
        if (context && context.auth) {
            if (isCandidate) {
                if (applied) {
                    showAlert({ message: t("you_are_already_applied_this_job"), status: false });
                } else {
                    if (resumes && resumes.id) {
                        fetchData(`job/${data.slug}/apply`, "POST", { resume_id: resumes && resumes.id, cover_letter: "yes" }, true, false, (res) => {
                            if (res.status) {
                                setApplied(true);
                                document.getElementById("closeJob").click();
                                document.getElementById("mainModal").click();
                            }
                        }
                        );
                    } else {
                        showAlert({ message: t("please_complete_your_profile_first"), status: false });
                    }
                }
            } else {
                showAlert({ message: t("please_login_as_a_candidate"), status: false });
            }
        } else {
            showAlert({ message: t("please_login_as_a_candidate_for_apply_a_job"), status: false, });
            Navigate("/sign-in");
        }
    };

    const checkSelectedResume = (id) => {
        if (!resumes.id) {
            showAlert({ message: t("please_select_your_resume"), status: false });
        } else {
            applyJob();
        }
    };

    const openPreviousModal = () => {
        document.getElementById("closeJob").click();
    };

    const closeApplyJobcByid = () => {
        document.getElementById("mainModal").click();
    };

    const openOpenLastModal = (id) => {
        document.getElementById(id).click();
    };
    return (
        <>
            {/* <div className="table-responsive"> */}
            <DataTables dt={dt} dt_name="jobs-list" />
            {/* </div> */}
            {/* {ReactDom.createPortal(1, document.getElementById("second_root"))} */}
            <ModalSection close_btn_id="closeJob" title={job && job.title} footer={
                job?.status === "expired" || job?.status === "pending" ?
                    <div className="row">
                        <div className="theme-btn">
                            <button className="default-btn" disabled={true}>
                                {job?.status === "pending" ? t("comming_soon") : t(job.status)}
                            </button>
                        </div>
                    </div>
                    :
                    <div className="row">
                        <div className="theme-btn">
                            <button data-toggle="modal" data-target={applied ? "" : "#applyHereJob"} className="default-btn" id="mainModal" onClick={() => openPreviousModal()}>
                                {applied ? t("applied") : t("apply_now")}
                            </button>
                        </div>
                    </div>
            } modal_id="viewJobList" size="xl">
                <JobDetail job={job} />
            </ModalSection>
            <div>
                <ModalSection title="Apply Job" modal_id="applyHereJob" size="xl" footer={
                    <div className="row">
                        <div className="theme-btn">
                            <button className="default-btn" onClick={() => checkSelectedResume()}>
                                {applied ? t("applied") : t("apply_now")}
                            </button>
                        </div>
                    </div>
                }
                    action={() => closeApplyJobcByid()}>
                    <div className="row">
                        <div className="main-pdf-box1">
                            {context?.auth?.resume_files?.map((resume, index) => {
                                return (
                                    <>
                                        <div className={`mb-3 pdf-box ${resumes.id === resume.id ? "resume_selected" : ""}`} key={index} onClick={() => setResumes(resume)}>
                                            <div className="pdf-icon position-relative">
                                                <img src="./images/pdf.png" alt="" />
                                                {/* <div className="show-button">
                          <div className="preview-btn">
                          </div>
                        </div> */}
                                                <div className="show-button  showBtnNew" data-toggle="modal" data-target={"#viewResumeJob_" + index} id={"closeApplyJobByid_" + index}>
                                                    <div className="preview-btn">
                                                        <i className="fa-solid fa-eye"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <p className="text-center mt-1 p-0 m-0">
                                                {resume.name.substring(0, 10)}
                                                {resume.name.length > 10 && "..."}
                                            </p>
                                            {/* <button
                        data-toggle="modal"
                        data-target={"#viewResumeJob_" + index}
                        className="default-btn"
                        id="closeApplyJobByid"
                      >
                        {"view"}
                      </button> */}
                                        </div>
                                    </>
                                );
                            })}
                        </div>
                    </div>
                </ModalSection>
            </div>
            {context?.auth?.resume_files?.map((resume, index) => {
                return (
                    <>
                        <ModalSection modal_id={"viewResumeJob_" + index} title="Resume" size="xl" key={index} close_btn_id="closeLastModal" action={() => openOpenLastModal("closeApplyJobByid_" + index)}>
                            <iframe className="priview_pdf" src={process.env.REACT_APP_ASSET_URL + resume.file} title="Resume 1"></iframe>
                        </ModalSection>
                    </>
                );
            })}
            <AdvanceFilter
                dt={dt}
                salaryFilter={true}
                dateRange="Date"
                filterList={['company', 'professions', 'countries', 'experiences', 'educations', 'job_category', 'job_types', 'salary_types', 'organization_type', 'industry_type', 'skill', 'team_size', 'job_roles', 'skill']}
                selectFilter={[
                    { key: "company", value: t("advanceFilter.Company") },
                    // { key: "professions", value: "Professions" },
                    // { key: "countries", value: "Countries" },
                    { key: "experiences", value: t("advanceFilter.Experiences") },
                    { key: "educations", value: t("advanceFilter.Educations") },
                    { key: "job_category", value: t("advanceFilter.Job Category") },
                    { key: "job_types", value: t("advanceFilter.Job Types") },
                    { key: "salary_types", value: t("advanceFilter.Salary Types") },
                    // { key: "organization_type", value: "Organization Type" },
                    // { key: "industry_type", value: "Industry Type" },
                    // { key: "skill", value: "Skill" },
                    // { key: "team_size", value: "Team Size" },
                    { key: "job_roles", value: t("advanceFilter.Job Roles") },
                    { key: "skill", value: t("advanceFilter.Skills") },
                ]}
                inputFilter={[
                    { key: "title", value: t("advanceFilter.Title") }
                ]}
                switchFilter={[
                    { key: "remote", value: t("advanceFilter.Remote Job") },
                    { key: "non_remote", value: t("advanceFilter.Non Remote Job") },
                    { key: "featured", value: t("advanceFilter.Featured") },
                    { key: "highlight", value: t("advanceFilter.Highlight") },
                ]}
            />
        </>
    );
};

export default JobList;
