// Settings Routes
export const GET_SITE_DATA = "site-info"
export const ADVANCE_FILTER_DROPDOWN = "advance-filter-dropdown"

// Auth Routes
export const REGISTER = "register"
export const LOGIN = "login"
export const LOGOUT = "logout"
export const FORGOT_PASSWORD = "forgot-password"
export const RESET_PASSWORD = "reset-password"
export const VERIFY_EMAIL = "verify-email"
export const GET_PROFILE = "get-my-profile"
export const UPDATE_PROFILE = "profile/update"
export const UPDATE_CV = "profile/cv-parser"
export const RESUME_VIDEO_UPLOAD = "profile/upload-resume-video"
export const DOWNLOAD_CV = "download-candidate-resume"
export const DELETE_RESUME = "profile/delete-resume"
export const UPDATE_PROFILE_IMAGE = "profile/update-avatar"
export const UPDATE_CONTACT_INFO = "profile/update-contact-info"
export const UPDATE_SOCIAL_LINK = "profile/update-social-links"
export const UPDATE_JOB_ALERT = "profile/receive-job-alert"
export const GET_SOCIAL_LINK = "profile/get-social-links"
export const GET_PERSONAL_INFO = "profile/get-personal-info"
export const UPDATE_PERSONAL_INFO = "profile/update-personal-info"


export const CHANGE_PASSWORD = "change-password"
export const GET_AUTH_USER = "profile"

// Chat Auth Routes
export const CHAT_AUTH = "chat/auth"
export const FETCH_MESSAGES = "fetchMessages"

export const CHATIFY = {
    auth: "chat/auth",
    fetchMessages: "fetchMessages",
    sendMessage: "sendMessage",
    getContacts: "getContacts",
    makeSeen: "makeSeen",
    setActiveStatus: "setActiveStatus",
    deleteConversation: "deleteConversation",
    search: "search",
    download: "download",
}

// CMS (Pages) Routes
export const HOME = "home"
export const BLOG = "posts"
export const BLOG_DETAILS = "post"
export const ALL_CATEGORY="job-category"
export const TEST_MAIL = "test-mail"
export const GET_MAIL = "get-mails"
export const FETCH_MAIL_FROM_SERVER= "fetch-imap-mail"
export const SEEN_MAIL = "seen-mail"
export const DETELE_MAIL = "delete-mail"
export const COMPOSE_MAIL = "compose-mail"
export const REPLY_MAIL = "mailReply"
export const BOOKMARK = "bookmark-mail"
export const SEARCH_MAIL = "search-mail"
export const GET_SERVER_MAIL = "get-all-mails"
export const GET_MAIL_LIST_FOR_IMAP = "get-mail-list-for-imap"

// Dashboard Routes
export const DASHBOARD = "profile/dashboard"
export const APPLIED_JOBS = "profile/applied-jobs"
export const JOB_ALERT = "profile/job-alerts"
export const CANDIDATE_MEETING = "profile/candidate-meetings"
export const CANDIDATE_DYTE_MEETING = "profile/candidate-dyte-meetings"
export const MEETING_OVER = "profile/meeting-status-update" 

export const FAVOURITE_JOBS = "candidate/favourite-jobs"
export const NOTIFICATIONS = "profile/notifications"
export const UNREAD_NOTIFICATIONS = "profile/notifications/unread"
export const READ_NOTIFICATIONS = "profile/notifications/read"
export const GET_JOBS = "get-jobs"
export const GET_JOB_DETAILS = "get-jobs-details"
export const GET_COMPANY_DETAILS = "company-details"
export const GET_JOB_FILTER = "get-jobs-filter"
export const Setting = "profile/Setting"
export const PLAN = "plans"
export const GET_JOB_ROLE = "get-jobs-role"
export const CANDIDATES = "candidates"
export const CANDIDATES_FILTER = "candidate-filter"
export const CANDIDATES_DETAILS = "candidate-details"

// Company Routes


export const COMPANY_DASHBOARD = "company/dashboard"
export const BUSINESS_LIST = "companies" 
export const CREATE_JOB_POST = "company/create-job-post" 
export const COMPANY_PROFILE_UPDATE = "company/profile-update" 
export const COMPANY_INFO_UPDATE= "company/company-info-update"
export const POST_JOB = "company/posted-jobs" 
export const DROP_DOWN =  "company/fetch-all-dropdown-data"
export const CANDIDATES_LIST = "company/bookmarked-candidates"
export const BOOKMARK_CANDIDATES_LIST = "company/bookmarked-candidates"
export const BOOKMARK_LIST = "company/list-bookmark-category"
export const CREATE_BOOKMARK = "company/create-bookmark"
export const UPDATE_BOOKMARK = "company/create-update-bookmark-category"
export const DELETE_BOOKMARK = "company/delete-bookmark-category"
export const EXPIRE_JOB = "company/make-job-post-expired"
export const CANDIDATE_BOOKMARK = "company/bookmark-candidate"
export const COMPANY_NOTIFICATION = "company/notifications"
export const BOOKMARK_CANDIDATE_CATEGORY = "company/fetch-dropdown-category"
export const FETCH_EMAIL_LIST = "company/fetch-email-list"
export const PROFILE_DELETE = "profile/delete-avatar"

export const PRODUCTS = "products"
export const UPDATE_STAFF_PERSONAL_INFO = "company/update-staff-personal-info"
export const UPDATE_STAFF_PROFILE_IMAGE = "company/update-staff-avatar"
export const UPDATE_COMPANY_SOCIAL_LINK = "company/social-links-update"
export const COMPANY_STAFF_LIST = "company/staff/list-staff"
export const COMPANY_CREATE_STAFF = "company/staff/create-staff"
export const COMPANY_STAFF_DELETE = "company/staff/delete-staff"
export const UPDATE_COMPANY_STAFF = "company/staff/update-staff"
export const UPDATE_COMPANY_CONTACT_INFO = "company/contact-details-update"
export const FAQ = "faq-data"
export const IMAP_DATA = "company/imap-data"
export const EMAIL_SETTING_CREATE = "company/imap-create"
export const CONTACT_FORM = "user-contact"


// job application endpoint using reduxtookkits
export const GET_COMPANY_JOB_APPLICATIONS = "company/job/applications"
export const COMPANY_JOB_APPLICATIONS_COLUMN="company/job/applications/column/store"
export const COMPANY_DELETE_GROUP = "company/job/applications/group/delete"
export const COMPANY_UPDATE_GROUP = "company/job/applications/group/update"
export const COMPANY_GET_JOB_APPLICATIONS_GROUP = "company/job/applications/columns-group"
export const COMPANY_PUT_JOB_MOVE_GROUP = "company/job/applications/sync"
export const COMPANY_CREATE_ORDER_PACKAGE = "company/order/create-order-package"
export const PAYPALPAYMENT = "company/pay-pal-checkout" 
export const STRIPE_CHECKOUT = "company/stripe-checkout"
export const VERIFY_STRIPE_PAYMENT = "company/verify-stripe-payment"
export const ORDER_INVOICE = "company/order-invoice"
export const COMPANY_CONSULTANT = "company/company-consultant"
export const COMPANY_PARSER_LOG = "company/company-parser-log"
export const VIEW_CANDIDATE_RESUME= "view-candidate-resume"

//Visitors
export const REGISTER_VISITORS = "profile/register-visitor"
export const VISITOR_LOG = "profile/visitor-logs"

//Mail-Gun-Get-Method
export const MAIL_CHAT_GET_THREADS = "mailchat/email-threads"
export const MAIL_CHAT_GET_EMAILS = "mailchat/emails"
export const MAIL_CHAT_GET_BY_ID = "mailchat/show"

//Mail-Gun-Post-Method
export const MAIL_CHAT_SEND = "mailchat/send"
export const MAIL_CHAT_REPLY = "mailchat/send-reply"

//company-candidate-details
export const COMPANY_CANDIDATE_LIST = "company/company-candidate"
export const COMPANY_CANDIDATE_CONNECT = "company/connect-to-company-consult"
export const COMPANY_CANDIDATE_LOGIN = "company/candidate/direct-login"
export const COMPANY_USER_CREATE = "company/candidate/create-candidate"
export const COMPANY_USER_CREATE_FROM_PARSER = "company/candidate/create-cadidate-parser"


export const GENERATE_MEETING = "company/generate-meeting-for-candidate"
export const GET_ALL_CANDIDATE = "company/get-all-candidate" 
export const GET_ALL_MEETINGS = "company/get-all-meetings" 
export const GET_WHATSAPP_CHAT = "company/get-whatsapp-chat" 
export const TRACK_IP_ADDRESS = "profile/track-meeting-ip-address" 



//multiple details
export const ADD_UPDATE_CANDIDATE_EDUCATION= "profile/add-update-candidate-education"
export const ADD_UPDATE_CANDIDATE_EXPERIENCE= "profile/add-update-candidate-work-experience"
export const ADD_UPDATE_CANDIDATE_CERTIFICATIONS= "profile/add-update-candidate-certifications"

export const DELETE_CANDIDATE_EDUCATION= "profile/delete-candidate-education"
export const DELETE_CANDIDATE_EXPERIENCE= "profile/delete-candidate-work-experience"
export const DELETE_CANDIDATE_CERTIFICATIONS= "profile/delete-candidate-certification"


//Dyte meeting

export const CREATE_DYTE_MEETING = "company/create-meeting"
export const UPDATE_DYTE_MEETING = "company/update-meeting"

export const DYTE_MEETING_LIST = "company/dyte-meeting-list"
