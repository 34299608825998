import { CANDIDATE_MEETING } from "../../components/Helpers/ApiRoutes";
import { useTranslation } from "react-i18next"
import { AuthSection } from "../../components/Layouts/Layout";
import CandidateMeetingList from "../../components/Partials/CandidateMeetingList.js";

const CandidateMeeting = () => {
    const { t } = useTranslation();

    return (
        <AuthSection icon={"fas fa-calendar-alt"} breadCrumbs={<span className="breadcrumb-item active">{t("nav.schduled-meeting")}</span>}>
            <CandidateMeetingList listUrl={CANDIDATE_MEETING} />
        </AuthSection>
    )
}

export default CandidateMeeting