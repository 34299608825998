import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { useTranslation } from "react-i18next";

export const EditButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? t('form.edit')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-pencil-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const DeleteButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('form.delete')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-danger me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-trash-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const ActiveButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('form.activate')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-check-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const DeactiveButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('form.deactivate')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-warning me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-x-circle-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const VerifyButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('form.email_verify')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn icon-white" onClick={props.action ?? null}>
                <img src="/images/user.svg" className="h-20px" alt="progress" />
            </button>
        </OverlayTrigger>
    )
}

export const AddButtonModal = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('form.add')} </Tooltip>}>
            <button id={props.id ?? null} className="btn btn-sm btn-outline-secondary me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-plus-circle-fill"></i> {props.title ? props.title  : ''}
            </button>
        </OverlayTrigger>
    )
}


export const EditButtonModal = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('form.edit')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-secondary me-1 action-btn" data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
                <i className="bi bi-pencil-fill"></i>
            </button>
        </OverlayTrigger>
    )
}


export const ViewButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? t('form.view')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-info me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-eye-fill"></i>
            </button>
        </OverlayTrigger>
    )
}

export const ViewButtonModal = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? t('form.view')} </Tooltip>}>
            <button className={`${props.className || "btn btn-sm btn-outline-info me-1 action-btn"}`} data-bs-toggle="modal" data-bs-target={props.modal_id} onClick={props.action ?? null}>
            {props.className ?"View":<i className="bi bi-eye-fill"></i>}
            </button>
        </OverlayTrigger>
    )
}

export const RedirectButtonLink = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? t('form.view')} </Tooltip>}>
            {/* add anchor tag */}
            <a href={props.href && props.href} className="btn btn-sm btn-outline-info me-1 action-btn" target={props.target && props.target}>
            <i className="bi bi-eye-fill"></i>
            </a>
        </OverlayTrigger>
    )
}

export const MeetingJoinButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip> {props.title ?? t('join')} </Tooltip>}>
            <button className="btn btn-sm btn-outline-success me-1 action-btn" onClick={props.action ?? null}>
                <i className="bi bi-link"></i>
            </button>
        </OverlayTrigger>
    )
}


export const HistoryButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? t('history')} </Tooltip>}>
            <button className={`${props.className || "btn btn-sm btn-outline-warning me-1 action-btn"}`} onClick={props.action ?? null}>
                <i className="fa fa-history"></i>
            </button>
        </OverlayTrigger>
    )
}

export const LoginButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? t('login')} </Tooltip>}>
            <button className={`${props.className || "btn btn-sm btn-outline-primary me-1 action-btn"}`} onClick={props.action ?? null}>
                <i className="fa fa-sign-in"></i>
            </button>
        </OverlayTrigger>
    )
}

export const MailButton = (props) => {
    const { t } = useTranslation();
    return (
        <OverlayTrigger placement="top" trigger={['click', 'focus', 'hover']} overlay={<Tooltip> {props.title ?? t('mail')} </Tooltip>}>
            <button className={`${props.className || "btn btn-sm btn-outline-info me-1 action-btn"}`} data-bs-toggle="modal" data-bs-target={props.modal_id} data-email={props.email ?? ""} onClick={props.action ?? null} props>
                <i className="fa-solid fa-envelope"></i>
            </button>
        </OverlayTrigger>
    )
}