import { useTranslation } from "react-i18next"
import "./style.scss"
import { useParams } from "react-router-dom"
import { useLayoutEffect } from "react"
import { decodedText } from "./Helpers/Helper"
const DotRoundLoader = (props) => {

    return (
        <>
            <style jsx="true">{`
        .application-loader{
            width: 90px;
            height: 50px;
            margin-top: 200px;
        }
        .application-loader-low{
            width: 90px;
            height: 50px;
            margin-top: 120px;
        }
        .loader-div{
            height: 690px;
        }
        .loader-div-low{
            height: 200px;
        }



          
      `}</style>
            {
                props.threeDot ?
                    <div className="spinner">
                        <div className="bounce1"></div>
                        <div className="bounce2"></div>
                        <div className="bounce3"></div>
                    </div>
                    :
                    <div className={props?.low ? `loader-div-low row justify-content-center align-item-center` : `loader-div row justify-content-center align-item-center`}>
                        <img alt="user" src="//upload.wikimedia.org/wikipedia/commons/thumb/e/e5/Phi_fenomeni.gif/50px-Phi_fenomeni.gif" className={props?.low ? "application-loader-low" : "application-loader"} />
                    </div>
            }
        </>
    )
}
export default DotRoundLoader

export const RedirectToNextDomain = () => {
    const { t } = useTranslation();
    const params = useParams()

    useLayoutEffect(() => {
        if (params && params.companyDomainToken) {
            try {
                localStorage.setItem('recruitment-token', JSON.parse(decodedText(params.companyDomainToken, process.env.REACT_APP_ENCRYPTED_KEY)))
                if (params.nextUrl) {
                    let info = JSON.parse(decodedText(params.nextUrl, process.env.REACT_APP_ENCRYPTED_KEY))
                    if (info && info.length > 0) {
                        info.forEach(element => {
                            if (element.value) {
                                localStorage.setItem(element.key, element.value)
                            }
                        });
                        setTimeout(() => {
                            window.location.replace(window.location.origin + "/setting")
                        }, 400)
                    } else {
                        console.log(123);
                        window.location.replace(window.location.origin + "/dashboard")
                    }
                } else {
                    window.location.replace(window.location.origin + "/dashboard")
                }
            } catch (error) {
                window.location.replace(window.location.origin + "/sign-in")
            }
        }
    }, [params])

    return (<>
        <div id="wrapper" className="theme-cyan">
            <div className="page-loader-wrapper d-none1">
                <div className="loader">
                    <div className="m-t-30">
                        <div className="spinner-border spinner-border-lg mx-3 text-white" role="status"><span className="visually-hidden"></span></div>
                    </div>
                    <p>{t("please_wait")}</p>
                </div>
            </div>
            <div id="main-content"></div>
        </div>
    </>)
}