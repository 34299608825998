import { useEffect } from "react";
import $ from 'jquery';

export const role = localStorage.getItem('role') || process.env.REACT_APP_ROLE
export const isCandidate = role === 'candidate' ? true : false
export const isCompany = role === 'company' ? true : false

export const setLayoutColor = (header_color = "#061322", sidebar_color = "#061322") => {
    let sidebar = document.getElementById("left-sidebar");
    let header = document.getElementById("nav-sidebar");
    if (header) header.style.backgroundColor = header_color
    if (sidebar) sidebar.style.backgroundColor = sidebar_color
}

export const SetLayoutColor = (props) => {

    useEffect(() => {
        setLayoutColor(props.primary, props.secondary)
        setTimeout(function() {
            $('.page-loader-wrapper').fadeOut();
        }, 1000);
    }, [props])

    return <>
        <style jsx="true">{`
        ::-webkit-scrollbar {
            background: ${props.secondary} !important;
        }
        ::-webkit-scrollbar-thumb {
            background-color: rgba(${props.primary}, 0.75) !important;
        }
        ::-webkit-scrollbar-thumb:hover {
            background-color: ${props.primary} !important;
        }
        #wrapper .btn.btn-primary, .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active{
            background: ${props.primary} !important;
            color: ${props.text} !important;
        }
        .dataTable tr th, .dataTables_wrapper .dataTables_paginate .paginate_button.current, .dataTables_wrapper .dataTables_paginate .paginate_button.current:hover{
            background: ${props.primary} !important;
            color: ${props.text} !important;
        }
        .bg-primary, .default-btn{
            background: ${props.primary} !important;
        }
        #wrapper .btn.btn-primary:hover, .bg-primary:hover, .default-btn:hover{
            background: ${props.secondary} !important;
            border-color: ${props.secondary} !important;
        }
        #wrapper .btn.btn-primary:focus, .bg-primary:focus, .default-btn:focus{
            box-shadow: 0 0 0 0.2rem ${props.secondary} !important;
        }
        #wrapper #nav-sidebar a, #wrapper #nav-sidebar i, #wrapper #left-sidebar a, #wrapper #left-sidebar i, .dropbtn, #wrapper #left-sidebar .user-account span, .log_out span{
            color: ${props.text} !important;
        }
      `}</style>
    </>
}