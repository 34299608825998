import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import DotRoundLoader from "../loader";
import { isBoolean } from "lodash";
import { stringTrim } from "./Helper";

export const ModalSection = (props) => {
    const loading = useMemo(() => {
        return isBoolean(props.loading) ? props.loading : false
    }, [props.loading]);

    const [load, setLoad] = useState(false)

    useEffect(() => {
        const myModalEl = document.getElementById(props.modal_id)

        function showLoader(e) {
            setLoad(true)
            setTimeout(() => setLoad(false), 500);
        }

        if (myModalEl) {
            myModalEl.addEventListener('show.bs.modal', showLoader)
        }

        return (() => {
            if (myModalEl) {
                myModalEl.removeEventListener('show.bs.modal', showLoader)
            }
        })
    }, [props.modal_id])

    return (
        //add class from props       
        <div className={`modal fade ${props.class}`} id={props.modal_id} tabIndex="-1" aria-labelledby={props.modal_id} aria-hidden="true" data-bs-backdrop="static" data-attr={props.data_attr ?? null}>
            <div className={`modal-dialog modal-dialog-${props.isNotScrolable ? '' : 'scrollable'} modal-${props.size}`}>
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title" title={props && props.title}> {props && props.title && stringTrim(props.title, 30)} </h5>
                        <button id={props.close_btn_id ?? null} type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.action ?? null}></button>
                    </div>
                    {(load || loading) && <DotRoundLoader low />}
                    <div className={`modal-body p-sm-4 ${(!load && !loading) ? "" : "opacity-0"}`} id={props.modal_body_id ?? null}>{props.children}</div>
                    {props.footer && <>
                        <div className="modal-footer bg-light d-block">
                            <div>{props.footer}</div>
                        </div>
                    </>}
                </div>
            </div>
        </div>
    )
}
export const ConfirmationModal = (props) => {
    const { t } = useTranslation();
    return (
        <div className={`modal fade ${props.class}`} id={props.modal_id ?? "confirmationModal"} tabIndex="-1" aria-labelledby={props.modal_id} aria-hidden="true" data-bs-backdrop="static">
            <div className={`modal-dialog  modal-${props.size ?? 'md'}`}>
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">{props.title ?? "Detele Item"}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={props.closeModal ?? null}></button>
                    </div>
                    <div className="modal-body p-sm-4 ">{props.children ?? <p className="">{t("are_you_sure_want_to_delete")}</p>}</div>
                    <div className="modal-footer bg-light">
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={props.action ?? null}>{props.operation ?? t('delete')}</button>
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={props.closeModal ?? null}>{t("cancel")}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}
export const ZoomImage = (props) => {
    const { t } = useTranslation();
    return (
        <div className="modal fade" id={props.modal_id ?? "zoomImage"} tabIndex="-1" aria-labelledby={props.modal_id} aria-hidden="true" data-bs-backdrop="static">
            <div className={`modal-dialog modal-dialog-centered modal-${props.size ?? 'lg'}`}>
                <div className="modal-content border-0 shadow">
                    <div className="modal-header bg-light">
                        <h5 className="modal-title">{props.title ?? "View Image"}</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="moday-body align-items-center justify-content-center w-100" style={{ minHeight: '60vh' }}>
                        <img src="" id={props.img_id} alt={t("zoom")} className="img-cover" style={{ 'height': 'inherit', 'width': 'inherit' }} />
                    </div>
                </div>
            </div>
        </div>
    )
}