import { AdvanceFilterModal, DateRangeFilter, InputFilter, SelectFilter, SwitchFilter } from "./Helper";
import { reloadUrlDataTable } from "../Helpers/Datatables";
import { useEffect, useState } from "react";
import { fetchData } from "../Helpers/Helper";
import { ADVANCE_FILTER_DROPDOWN } from "../Helpers/ApiRoutes";
import MultiRangeSlider from "multi-range-slider-react";
import { useTranslation } from "react-i18next"
import { now } from "lodash";

export const AdvanceFilter = (props) => {
    const { t } = useTranslation();
    const [selectFilterField, setSelectFilterField] = useState([])
    const [inputFilterField, setInputFilterField] = useState([])
    const [switchFilterField, setSwitchFilterField] = useState([])
    const [switchField, setSwitchField] = useState(null)
    const [inputField, setInputField] = useState(null)
    const [selectField, setSelectField] = useState(null)
    const [selectDropDown, setSelectDropDown] = useState([])
    const [minValue, set_minValue] = useState(0);
    const [maxValue, set_maxValue] = useState(100000);
    const [dateRangeQuery, setDateRangeQuery] = useState(null);
    const [resetDateRange, setResetDateRange] = useState(now());

    useEffect(() => {
        let list = props.filterList ?? []
        setSelectFilterField(props.selectFilter ?? [])
        setInputFilterField(props.inputFilter ?? [])
        setSwitchFilterField(props.switchFilter ?? [])
        fetchData(`${ADVANCE_FILTER_DROPDOWN}?list=${list}`, 'GET', '', true, true, (res) => {
            if (res && res.body) {
                setSelectDropDown(res.body)
            }
        })
    }, [props])

    const handleInput = (e) => {
        set_minValue(e.minValue);
        set_maxValue(e.maxValue);
    };

    const filter = () => {
        let query = "";

        for (const key in inputField) {
            if (inputField[key]) {
                query = `${query}&${key}=${inputField[key]}`;
            }
        }

        for (const key in selectField) {
            if (selectField[key]) {
                query = `${query}&${key}=${selectField[key].value}`;
            }
        }

        for (const key in switchField) {
            if (switchField[key]) {
                query = `${query}&${key}=${switchField[key]}`;
            }
        }

        if (props.salaryFilter) {
            query = `${query}&min_sallary=${minValue}&max_sallary=${maxValue}`;
        }

        if (props.dateRange && dateRangeQuery) {
            query = `${query}&${dateRangeQuery}`;
        }

        reloadUrlDataTable(props.dt, `${props.dt.dt_url}?${query}`)
    }

    const reset = () => {
        set_minValue(0);
        set_maxValue(100000);
        setInputField(null)
        setSelectField(null)
        setSwitchField(null)
        setDateRangeQuery(null)
        setResetDateRange(now())
        reloadUrlDataTable(props.dt, props.dt.dt_url)
    }

    return (<>
        <style jsx="true">{`
            .multi-range-slider{
                border: none;
                border-radius: none;
                padding: 10px 20px;
                box-shadow: none;
            }
            .multi-range-slider .thumb .caption *{
                background-color: #061322;
            }
            .multi-range-slider .thumb{
                z-index: 0;
            }
        `}</style>
        <AdvanceFilterModal filter={filter} reset={reset}>
            <div className="row">
                {selectFilterField.map((item, key) => {
                    return <SelectFilter
                        isClearable
                        key={key}
                        name={item.key}
                        label={item.value}
                        placeholder={item.value}
                        value={selectField && selectField[item.key] ? selectField[item.key] : null}
                        theme={(theme) => ({ ...theme, colors: { ...theme.colors, primary: 'black' } })}
                        onChange={(e) => setSelectField(prevState => ({ ...prevState, [item.key]: e }))}
                        options={selectDropDown && selectDropDown[item.key] ? selectDropDown[item.key] : []}
                    />
                })}

                {inputFilterField.map((item, key) => {
                    return <InputFilter
                        key={key}
                        name={item.key}
                        label={item.value}
                        placeholder={item.value}
                        value={inputField && inputField[item.key] ? inputField[item.key] : ""}
                        onChange={(e) => setInputField(prevState => ({ ...prevState, [item.key]: e.target.value }))}
                    />
                })}

            </div>

            <div className="row">
                {switchFilterField.map((item, key) => {
                    return <SwitchFilter
                        key={key}
                        name={item.key}
                        label={item.value}
                        checked={switchField && switchField[item.key] ? true : false}
                        onChange={(e) => setSwitchField(prevState => ({ ...prevState, [item.key]: e.target.checked }))}
                    />
                })}

                {props.salaryFilter && <>
                    <div className="mb-3 mx-0 px-2 col-12 col-sm-12 col-md-9">
                        <label>{props.salaryFilter.title ?? t("Salary Filter")} ({minValue} - {maxValue})</label>
                        <MultiRangeSlider min={0} max={100000} step={1000} stepOnly={true} ruler={false} label={false} minValue={minValue} maxValue={maxValue} thumbLeftColor="#061322" thumbRightColor="#061322" barInnerColor="#061322" onInput={handleInput} />
                    </div>
                </>}

                {props.dateRange && <>
                    <div className="mb-3 mx-0 px-2 col-12 col-sm-8 col-md-7 col-lg-4">
                        <label htmlFor="date-range-filter" className="form-label">
                            {props.dateRange}
                        </label>
                        <div className="">
                            <DateRangeFilter dateRangeQuery={setDateRangeQuery} reset={resetDateRange} />
                        </div>
                    </div>
                </>}

            </div>
        </AdvanceFilterModal>
    </>)
}

// How to use?

// props = dt,inputFilter,selectFilter,filterList
// min_sallary & max_sallary
// props.inputFilter and props.selectFilter must be like this- [{ key: "education", value: "Education" }]
// only use filterList `` ['professions','countries','experiences','educations','job_category','job_types','salary_types','organization_type','industry_type','skill','team_size','job_roles'] `` this key for filter list in api and get advance filter dropdown data

// dt_filter: () => createRoot(document.getElementById("dt-custom-filter")).render(<FilterButton />)
// {/* <AdvanceFilter dt={dt} sallaryFilter={true} filterList={['professions', 'countries']} inputFilter={[]} selectFilter={[{ key: "professions", value: "Professions" }, { key: "countries", value: "Countries" }]} /> */}