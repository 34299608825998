import { useTranslation } from "react-i18next";



const MailList = ({ mailBox, mailData, viewDetails }) => {
    const { t } = useTranslation();

    return (
        <>
            <div className="mail-list">
                <ul className="list-unstyled cursor-pointer" >
                    <div>
                        <div className="container-fluid ">
                            <div className="row clearfix">
                                <div className="col-lg-12">
                                    <div className="mail-list">
                                        <ul className="list-unstyled">
                                            {mailData && mailData.total > 0 ? mailData.data.map((item, key) => {
                                                return (
                                                    <li role="button" className={`clearfix ${(!item.seen) && 'bg-light'}`} key={key}>
                                                        <div onClick={() => viewDetails(item)}>

                                                            <div className="d-flex justify-content-between">
                                                                <div>
                                                                    <span className="extra-bold">{item.reply_mail}</span>
                                                                </div>
                                                                <div>
                                                                    <span className="extra-bold time">{item.mail_date}</span>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex justify-content-between mt-2">
                                                                <p class="d-inline-block text-truncate" style={{ maxWidth: "95%", marginBottom: "0" }}><b className="m-r-10">{item.subject}</b><span></span></p>
                                                                {item.unseen_count > 1 && <span className="numbering_box">{item.unseen_count}</span>}
                                                            </div>
                                                        </div>
                                                    </li>
                                                )
                                            }) : <p className="text-center mt-2">{t("no_mail_found")}</p>}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </ul>
            </div>
        </>
    )
}

export default MailList
