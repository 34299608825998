import { AuthSection } from "../../components/Layouts/Layout";
import { useTranslation } from "react-i18next"
import { APPLIED_JOBS } from "../../components/Helpers/ApiRoutes";
import JobList from "../../components/Partials/JobList";
import { Link, useLocation } from "react-router-dom";

const CandidateHistory = () => {
    const { t } = useTranslation();
    const location = useLocation();

    return (
        <AuthSection breadCrumbs={<>
            {location.state.breadcrumb?.length > 0 && location.state.breadcrumb.map((item, index) => {
                return <li key={index} className="breadcrumb-item"><Link to={item.url}><i className={`${item.icon} me-2`}></i> {item.title}</Link></li>
            })}
            <span className="breadcrumb-item active"><i className="fa fa-history mt-1 me-2"></i> {t('candidate_history')}</span>
        </>}>
            <JobList listUrl={`${APPLIED_JOBS}/${location.state.candidate_id}`} />
        </AuthSection>
    )
    }
export default CandidateHistory