import { useState } from "react";
import CandidateMeeting from "./CandidateMeeting";
import CandidateDyteMeeting from "./CandidateDyteMeeting";
import { useTranslation } from "react-i18next";


const CandidateScheduleMeeting = () => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState();

    const handleActiveChangeTab = (tab) => {
        setActiveTab(tab)
    }
    return (
        <div className="row">
                    <div className="col-sm-12">
                        <div className="py-0 py-sm-4">
                            <ul className="nav nav-tabs manage_nav_flex" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${activeTab === 'schdule' && 'active show'}`} id="schdule" data-bs-toggle="tab" data-bs-target="#schdule-pane" type="button" role="tab" aria-controls="schdule-pane" aria-selected="true" onClick={() => handleActiveChangeTab('schdule')}>
                                        <i className="fa fa-address-card me-2" aria-hidden="true"></i>{t("nav.schdule_meeting")} 
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className={`nav-link ${activeTab === 'dyte' && 'active show'}`} id="dyte" data-bs-toggle="tab" data-bs-target="#dyte-pane" type="button" role="tab" aria-controls="dyte-pane" aria-selected="true" onClick={() => handleActiveChangeTab('dyte')}>
                                        <i className="fa fa-address-card me-2" aria-hidden="true"></i>{t("nav.dyte_meeting")}
                                    </button>
                                </li>
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                <div className={`tab-pane fade ${activeTab === 'schdule' && 'show active'}`} id="schdule-pane" role="tabpanel" aria-labelledby="schdule" tabIndex="0">
                                    <CandidateMeeting />
                                </div>
                                <div className={`tab-pane fade ${activeTab === 'dyte' && 'show active'}`} id="dyte-pane" role="tabpanel" aria-labelledby="dyte" tabIndex="0">
                                    <CandidateDyteMeeting/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
    )
}

export default CandidateScheduleMeeting