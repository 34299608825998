import _ from "lodash"
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { fetchData } from "../../../components/Helpers/Helper";
import { FETCH_MAIL_FROM_SERVER } from "../../../components/Helpers/ApiRoutes";

const MailFilter = ({ mailBox, mailData, loadData, setSearch, setPage, completeEmailSetting }) => {
    const [text, setText] = useState('');
    const { t } = useTranslation();

    const fetchMailFromServer = () => {
        fetchData(FETCH_MAIL_FROM_SERVER, 'GET', '', true, false, (res) => { })
        loadData()
    }

    return (
        <>
            <div className="header d-flex align-center">
                <h2 style={{color: "#061322"}}>{_.capitalize(mailBox)}</h2>
                <form className="ml-auto">
                    <div className="input-group">
                        <input type="text" className="form-control" value={text} onChange={(e) => setText(e.target.value)} placeholder={t("search_mail")} aria-label="Search Mail" aria-describedby="search-mail" />
                        <div className="input-group-append" role="button" onClick={() => setSearch(text)}>
                            <span className="input-group-text" id="search-mail"><i className="fa fa-search" aria-hidden="true"></i></span>
                        </div>
                    </div>
                </form>
            </div>
            <div className="mail-action clearfix border-bottom">
                <div className="float-left">
                    <div className="btn-group">
                        <button disabled={!completeEmailSetting} className="btn btn-outline-secondary btn-sm hidden-sm" onClick={fetchMailFromServer}><i className="fa fa-refresh">&nbsp;</i>{t("refresh")}</button>
                        <button id="mail-setting-btn" className="btn btn-outline-secondary btn-sm hidden-sm" data-bs-toggle="modal" data-bs-target="#mailSettingModal"><i className="fa fa-gear">&nbsp;</i>{t("nav.setting")}</button>
                    </div>
                </div>
                <div className="float-right ml-auto">
                    {mailData && <>
                        <div className="pagination-email d-flex align-items-center">
                            <p>
                                {mailData.from ?? 0}-{mailData.to ?? 0}/{mailData.total}
                            </p>
                            <div className="btn-group ml-2">
                                <button type="button" className="btn btn-outline-secondary btn-sm" disabled={!mailData.prev_page_url} onClick={() => setPage(prev => prev - 1)}>
                                    <i className="fa fa-angle-left"></i>
                                </button>
                                <button type="button" className="btn btn-outline-secondary btn-sm" disabled={!mailData.next_page_url} onClick={() => setPage(prev => prev + 1)}>
                                    <i className="fa fa-angle-right"></i>
                                </button>
                            </div>
                        </div>
                    </>}
                </div>

            </div>
        </>
    )
}

export default MailFilter
